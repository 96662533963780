import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { WalletAction } from 'src/redux';

const useOnDisconnectedWithMetaMask = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(WalletAction.disconnectedWithMetaMask());
  }, [dispatch]);
};

export default useOnDisconnectedWithMetaMask;
