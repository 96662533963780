import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { roundValues } from '../common';

interface RangeSelectionProps {
  currentValues: number[] | undefined;
  step: number;
  min: number;
  max: number;
  minStepsBetweenThumbs?: number;
  onChange: (values: number[]) => void;
  unit?: string | null;
  title?: string;
}

const RangeSelection = (props: RangeSelectionProps) => {
  const {
    currentValues,
    step,
    min,
    max,
    minStepsBetweenThumbs = 1,
    onChange,
    title = '',
    unit = '',
  } = props;
  const [values, setValues] = useState(currentValues);
  useEffect(() => {
    setValues(currentValues);
  }, [currentValues]);
  const selectedMinValue = get(values, 0, 0);
  const selectedMaxValue = get(values, 1, 0);
  const defaultBg = useColorModeValue(Colors.light.selectionBg, Colors.dark.selectionBg);
  const primaryColor = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  return (
    <Box pos={'relative'} w={'full'} padding={4}>
      <Text marginLeft={'-6px'} color={descriptionColor}>
        {title}
      </Text>
      <RangeSlider
        value={values}
        minStepsBetweenThumbs={minStepsBetweenThumbs}
        min={min}
        max={max}
        step={step}
        focusThumbOnChange={false}
        onChange={(val: number[]) => setValues(roundValues(val))}
        onChangeEnd={(val: number[]) => onChange(roundValues(val))}
      >
        <RangeSliderTrack bg={defaultBg}>
          <RangeSliderFilledTrack bg={primaryColor} />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={3} index={0} name={'min'}>
          <Text pos={'absolute'} top={'20px'}>
            {selectedMinValue}&nbsp;{unit}
          </Text>
        </RangeSliderThumb>
        <RangeSliderThumb boxSize={3} index={1} name={'max'}>
          <Text pos={'absolute'} top={'20px'}>
            {selectedMaxValue}&nbsp;{unit}
          </Text>
        </RangeSliderThumb>
      </RangeSlider>
    </Box>
  );
};

export default RangeSelection;
