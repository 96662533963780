import { Flex } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { coreUtils } from 'src/common';
import { INFT } from '../gql/types';
import { SLIME_SPINE_RESOURCE_URL } from '../globals/configs';
import Spinner from './Spinner';

interface SlimeSpinePreviewProps {
  slime?: INFT | null;
  renderKey?: string;
  isLoading?: boolean;
}

const DefaultSkin = 'x_full_skin/Com/Com_as_Bonesaw';

const SlimeSpinePreview = ({ renderKey, slime, isLoading }: SlimeSpinePreviewProps) => {
  const slimeSpinePreviewRef = useRef<any | null>();
  const [isSpinePlayerReady, setIsSpinePlayerReady] = useState(false);

  useEffect(() => {
    if (slime && slimeSpinePreviewRef.current) {
      const skins = coreUtils.getSlimeSkinsFromSlimeData(slime);
      const spineSkins = coreUtils.getSpineSkin(skins, slimeSpinePreviewRef.current);
      if (spineSkins) {
        coreUtils.setSlimeSpineSkin(slimeSpinePreviewRef.current, spineSkins);
      }
    }
  }, [isSpinePlayerReady, slime]);

  const isShowLoading = useMemo(
    () => isLoading || !isSpinePlayerReady,
    [isLoading, isSpinePlayerReady]
  );

  const renderKeyUsed = useMemo(
    () => `slime-spine-preview-${renderKey || Math.random().toString()}`,
    []
  );

  useEffect(() => {
    // @ts-ignore
    new spine.SpinePlayer(renderKeyUsed, {
      jsonUrl: `${SLIME_SPINE_RESOURCE_URL}/Slime.json`,
      atlasUrl: `${SLIME_SPINE_RESOURCE_URL}/Slime.atlas`,
      showControls: false,
      skin: DefaultSkin,
      animation: 'idle',
      alpha: true,
      backgroundColor: '#00000000',
      success: (player: any) => {
        slimeSpinePreviewRef.current = player;
        setIsSpinePlayerReady(true);
      },
      error: (player: any) => {
        console.error('Load spine error');
        slimeSpinePreviewRef.current = player;
        setIsSpinePlayerReady(true);
      },
      preserveDrawingBuffer: false,
    });
  }, []);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      position="relative"
      width="100%"
      height="100%"
      maxHeight="80vh"
    >
      <Flex
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        visibility={isShowLoading ? 'hidden' : 'visible'}
      >
        <div id={renderKeyUsed} />
      </Flex>
      {isShowLoading && (
        <div>
          <Spinner />
        </div>
      )}
    </Flex>
  );
};

export default SlimeSpinePreview;
