import { useCallback, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { sleep } from 'src/common/utils';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';

const useUpdateNFTPriceOnFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isUpdateNFTPriceOnFixedPriceSaleMarket, setIsUpdateNFTPriceOnFixedPriceSaleMarket] =
    useState<boolean>(false);
  const onUpdateNFTPriceOnFixedPriceSaleMarket = useCallback(
    (saleId: string, newPrice: string, onUpdatePriceSuccess?: (txid: string) => void) => {
      setError(undefined);
      setIsUpdateNFTPriceOnFixedPriceSaleMarket(true);
      MetamaskProvider.updateSalePriceOfNFTFromFixedSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType),
        saleId,
        newPrice
      )
        .then(async (receipt) => {
          await sleep(5000);
          if (onUpdatePriceSuccess) {
            onUpdatePriceSuccess(receipt.transactionHash);
          }
        }) // For delay confirmation
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsUpdateNFTPriceOnFixedPriceSaleMarket(false);
        });
    },
    [nftType, setIsUpdateNFTPriceOnFixedPriceSaleMarket]
  );

  return [isUpdateNFTPriceOnFixedPriceSaleMarket, onUpdateNFTPriceOnFixedPriceSaleMarket, error];
};

export default useUpdateNFTPriceOnFixedPriceSaleMarket;
