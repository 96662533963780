import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MetamaskProvider from 'src/metamask-provider';
import { RootReducerState } from 'src/redux';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import { useIsMounted } from 'src/hooks';

const useApproveFTCTokenToFixedPriceSaleMarket = (nftType: TNFTType) => {
  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );
  const [error, setError] = useState<string | undefined>();
  const [isGettingApprovedFTCTokenStatus, setIsGettingApprovedFTCTokenStatus] = useState<
    boolean | undefined
  >();
  const [isApprovingFTCToken, setIsApprovingFTCToken] = useState<boolean | undefined>();
  const [isApprovedFTCTokenForSale, setIsApprovedFTCTokenForSale] = useState<boolean | undefined>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (metaMaskWalletAddress) {
      if (isMounted()) {
        setError(undefined);
        setIsGettingApprovedFTCTokenStatus(true);
      }
      MetamaskProvider.checkApprovedFantasyTokenToFixedPriceSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType)
      )
        .then((isApproved) => {
          if (isMounted()) setIsApprovedFTCTokenForSale(isApproved);
        })
        .catch((error) => {
          if (isMounted()) setError(error.message);
          console.error(error);
        })
        .finally(() => {
          if (isMounted()) setIsGettingApprovedFTCTokenStatus(false);
        });
    }
  }, [isMounted, nftType, setIsGettingApprovedFTCTokenStatus, metaMaskWalletAddress]);

  // Only call approve if the tokenId is set and the token is not approved
  const onApprovedFTCTokenToFixedPriceSale = useCallback(() => {
    if (!isApprovedFTCTokenForSale) {
      setError(undefined);
      setIsApprovingFTCToken(true);
      MetamaskProvider.setApprovedFantasyTokenToFixedPriceSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType),
        true
      )
        .then(() => {
          setIsApprovedFTCTokenForSale(true);
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsApprovingFTCToken(false);
        });
    }
  }, [nftType, isApprovedFTCTokenForSale, setIsApprovingFTCToken, setIsApprovedFTCTokenForSale]);

  return [
    onApprovedFTCTokenToFixedPriceSale,
    isGettingApprovedFTCTokenStatus,
    isApprovingFTCToken,
    isApprovedFTCTokenForSale,
    error,
  ];
};

export default useApproveFTCTokenToFixedPriceSaleMarket;
