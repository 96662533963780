import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import theme from 'src/shared/theme';
import { store, persistor } from 'src/redux';
import { apolloClient } from 'src/gql';
import * as Locales from 'src/locales';
import { ErrorModal } from 'src/features/errorModal';
import { AppConfig } from 'src/features/appConfig/components';
import { DateAndTime } from 'src/features/dateAndTime';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RootRouter from './RootRouter';

Locales.initLocales();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
              <AppConfig />
              <DateAndTime />
              <RootRouter />
              <ErrorModal />
            </ChakraProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
