import { Box, VStack } from '@chakra-ui/react';
import React from 'react';
import { ISlimeMetadata } from '../../../../gql/types';
import PassiveSkill from './PassiveSkill';
import ActiveSkill from './ActiveSkill';
import SpecialSkill from './SpecialSkill';

interface SkillStatPropsType {
  metadata: ISlimeMetadata;
}

const Skills = ({ metadata }: SkillStatPropsType) => {
  return (
    <VStack alignItems="start">
      <Box w="100%">
        <PassiveSkill metadata={metadata} />
      </Box>
      <Box w="100%">
        <ActiveSkill metadata={metadata} />
      </Box>
      {metadata.mythicalSkill !== '0' && (
        <Box w="100%">
          <SpecialSkill metadata={metadata} />
        </Box>
      )}
    </VStack>
  );
};

export default Skills;
