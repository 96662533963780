import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAccountInformation from '../hooks/useAccountInformation';
import useBindToAccountByWallet from '../hooks/useBindToAccountByWallet';
import { BindingType } from '../../../types/AccountServiceClient';
import { RootReducerState } from '../../../redux';
import BaseFieldSetting from './BaseFieldSetting';

interface UsernameSettingProps {}

const UsernameSetting = (props: UsernameSettingProps) => {
  const { t } = useTranslation();

  const { isAuthenticated, accountInfo } = useAccountInformation();
  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );

  const { isBinding, bind } = useBindToAccountByWallet(BindingType.username, metaMaskWalletAddress);

  const onConfirmChange = useCallback(
    async (value: string) => {
      await bind({ username: value });
    },
    [bind]
  );

  return (
    <BaseFieldSetting
      disabled={!isAuthenticated}
      label={t('Common:Username')}
      currentValue={accountInfo?.username || ''}
      onConfirmChange={onConfirmChange}
      placeholder={t('Component:AccountSettings.UsernamePlaceholder')}
      loading={isBinding}
    />
  );
};

export default UsernameSetting;
