import { RecentlyListDataState, RecentlyListState } from 'src/redux/dashboard/types';
import { TNFTType } from 'src/gql/types';
import { DashboardCommon } from 'src/redux/dashboard';

export const getRecentlyListDataByNFTType = (
  recentlyList: RecentlyListState,
  nftType: TNFTType
): RecentlyListDataState => {
  const recentlyListKey = DashboardCommon.getRecentlyListDataKey(nftType);
  return recentlyList[recentlyListKey] as RecentlyListDataState;
};
