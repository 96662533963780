import { TNFTType } from 'src/gql/types';
import { NFTType } from 'src/globals/constants';
import * as Types from './types';
import { RecentlyListDataKey } from './types';

export const getRecentlyListDataKey = (nftType: TNFTType): Types.TRecentlyListDataKey => {
  switch (nftType) {
    case NFTType.MINING_TOOL:
      return RecentlyListDataKey.MINING_TOOLS;
    case NFTType.GENE:
      return RecentlyListDataKey.GENES;
    case NFTType.SLIME:
      return RecentlyListDataKey.SLIMES;
    case NFTType.LAND:
      return RecentlyListDataKey.LANDS;
    case NFTType.GACHA:
      return RecentlyListDataKey.GACHA_BOXES;
    case NFTType.TOOL_MATERIAL:
      return RecentlyListDataKey.TOOL_MATERIALS;
    default:
      throw new Error(`getRecentlyListDataKey Unsupported NFT type ${nftType}`);
  }
};
