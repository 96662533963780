import { Box, Flex, Heading, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Colors } from 'src/shared';
import { Pagination } from '../../components';
import { SortingSelector } from '../../features/sorting';
import {
  DefaultLimit,
  GeneSortTypes,
  LandSortTypes,
  MiningToolSortTypes,
  NFTType,
  SlimeSortTypes,
  StandardSortTypes,
} from '../../globals/constants';
import { RootReducerState } from '../../redux';
import RefreshInventoryButton from './RefreshInventoryButton';

interface InventoryHeaderProps {
  nftType: NFTType;
}

const InventoryHeader = (props: InventoryHeaderProps) => {
  const { nftType } = props;
  const { t } = useTranslation();
  const [totalOfNfts, setTotalOfNfts] = React.useState(0);
  const [totalOfPages, setTotalOfPages] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [sortType, setSortType] = React.useState(StandardSortTypes);

  const defaultBg = useColorModeValue(Colors.light.secondaryBg, Colors.dark.secondaryBg);

  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );
  const totalOfMyMiningTools = useSelector(
    (state: RootReducerState) => state.inventory.totalOfMyMiningTools
  );
  const totalOfMyToolMaterials = useSelector(
    (state: RootReducerState) => state.inventory.totalOfMyToolMaterials
  );
  const totalOfMyLands = useSelector((state: RootReducerState) => state.inventory.totalOfMyLands);
  const totalOfMyGachaBoxes = useSelector(
    (state: RootReducerState) => state.inventory.totalOfMyGachaBoxes
  );
  const totalOfMyGenes = useSelector((state: RootReducerState) => state.inventory.totalOfMyGenes);
  const totalOfMySlimes = useSelector((state: RootReducerState) => state.inventory.totalOfMySlimes);

  useEffect(() => {
    switch (nftType) {
      case NFTType.MINING_TOOL:
        setTotalOfNfts(totalOfMyMiningTools);
        setTotalOfPages(Math.ceil(totalOfMyMiningTools / DefaultLimit));
        setTitle(t('PageName:MiningTool'));
        setSortType(MiningToolSortTypes);
        break;
      case NFTType.TOOL_MATERIAL:
        setTotalOfNfts(totalOfMyToolMaterials);
        setTotalOfPages(Math.ceil(totalOfMyToolMaterials / DefaultLimit));
        setTitle(t('PageName:ToolMaterial'));
        setSortType(StandardSortTypes);
        break;
      case NFTType.LAND:
        setTotalOfNfts(totalOfMyLands);
        setTotalOfPages(Math.ceil(totalOfMyLands / DefaultLimit));
        setTitle(t('PageName:Land'));
        setSortType(LandSortTypes);
        break;
      case NFTType.GACHA:
        setTotalOfNfts(totalOfMyGachaBoxes);
        setTotalOfPages(Math.ceil(totalOfMyGachaBoxes / DefaultLimit));
        setTitle(t('PageName:Gacha'));
        setSortType(StandardSortTypes);
        break;
      case NFTType.GENE:
        setTotalOfNfts(totalOfMyGenes);
        setTotalOfPages(Math.ceil(totalOfMyGenes / DefaultLimit));
        setTitle(t('PageName:Gene'));
        setSortType(GeneSortTypes);
        break;

      case NFTType.SLIME:
        setTotalOfNfts(totalOfMySlimes);
        setTotalOfPages(Math.ceil(totalOfMySlimes / DefaultLimit));
        setTitle(t('PageName:Slime'));
        setSortType(SlimeSortTypes);
        break;
    }
  }, [
    nftType,
    totalOfMyMiningTools,
    totalOfMyToolMaterials,
    totalOfMyLands,
    totalOfMyGachaBoxes,
    totalOfMyGenes,
    totalOfMySlimes,
    t,
  ]);

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      justifyContent={{ base: 'center', lg: 'space-between' }}
      padding={{ base: 5, lg: 0 }}
      alignItems="center"
      position="sticky"
      top={-5}
      background={defaultBg}
      zIndex={8}
    >
      <Box as="span">
        <Heading as="h2" size="lg" color="heading">
          {t(title)} ({totalOfNfts.toLocaleString('en')})
          {metaMaskWalletAddress && (
            <Tooltip label={t('Refresh')} hasArrow>
              <RefreshInventoryButton refreshNFTType={nftType} ownerId={metaMaskWalletAddress} />
            </Tooltip>
          )}
        </Heading>
      </Box>
      <Pagination pageTotal={totalOfPages} />
      <SortingSelector options={sortType} />
    </Flex>
  );
};

export default InventoryHeader;
