import { useCallback, useEffect, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { ContractType } from '../../../metamask-provider/type';
import useErrorModal from '../../errorModal/hooks/useErrorModal';

const useApprovalSetting = (contractType: ContractType, isFTC = false) => {
  const [openErrorModal] = useErrorModal();
  const [hasApproved, setHasApproved] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadApprovalStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const approved = await MetamaskProvider.checkApprovedStatusToFixedPriceSaleMarket(
        contractType,
        isFTC
      );
      setHasApproved(approved);
    } catch (error: any) {
      console.error(error);
      openErrorModal(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [contractType, isFTC, openErrorModal]);

  const setApprovalForAll = useCallback(
    async (newApproved: boolean) => {
      try {
        setIsLoading(true);
        await MetamaskProvider.approveToFixedPriceSaleMarket(contractType, isFTC, newApproved);
        setHasApproved(newApproved);
      } catch (error: any) {
        console.error(error);
        openErrorModal(error.message);
        setHasApproved(hasApproved);
      } finally {
        setIsLoading(false);
      }
    },
    [contractType, hasApproved, isFTC, openErrorModal]
  );

  useEffect(() => {
    loadApprovalStatus();
  }, [loadApprovalStatus]);

  return { isLoading, hasApproved, setApprovalForAll };
};

export default useApprovalSetting;
