import React, { useCallback, useEffect } from 'react';
import { Button, Text, useDisclosure, usePrevious } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import { AppSizes } from 'src/shared';
import useErrorModal from 'src/features/errorModal/hooks/useErrorModal';
import { TNFTType } from 'src/gql/types';
import { useIsMounted } from 'src/hooks';
import useRemoveNFTFromFixedPriceSaleMarket from '../hooks/useRemoveNFTFromFixedPriceSaleMarket';
import ConfirmCancelSellModal from './ConfirmCancelSellModal';

interface ICancelSellButtonProps {
  saleId?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onCancelSellDone?: (txid: string) => void;
  nftType: TNFTType;
}

const CancelSellButton = ({
  saleId,
  isLoading,
  onCancelSellDone,
  nftType,
  isDisabled,
}: ICancelSellButtonProps) => {
  const { t } = useTranslation();
  const [openErrorModal] = useErrorModal();

  const isMounted = useIsMounted();

  const [
    isRemovingNFTFromFixedPriceSale,
    onRemoveNFTFromFixedPriceSaleMarket,
    removeSaleErrorMessage,
  ] = useRemoveNFTFromFixedPriceSaleMarket(nftType);

  const {
    isOpen: isOpenConfirmModal,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = useDisclosure({ defaultIsOpen: false });

  const onClickCancelSell = useCallback(() => {
    onOpenConfirmModal();
  }, [onOpenConfirmModal]);

  const onConfirmCancelSell = useCallback(async () => {
    onCloseConfirmModal();
    if (saleId && isFunction(onRemoveNFTFromFixedPriceSaleMarket)) {
      onRemoveNFTFromFixedPriceSaleMarket(saleId, onCancelSellDone);
    }
  }, [saleId, onRemoveNFTFromFixedPriceSaleMarket, onCloseConfirmModal, onCancelSellDone]);

  const isButtonLoading = !!isLoading || !!isRemovingNFTFromFixedPriceSale;
  const isButtonDisabled = isButtonLoading || !!isDisabled;

  let buttonLoadingText;
  if (isRemovingNFTFromFixedPriceSale) {
    buttonLoadingText = t('Component:CancelSellButton.Canceling');
  }

  const prevRemoveSaleErrorMessage = usePrevious<string | undefined>(
    removeSaleErrorMessage as string
  );
  useEffect(() => {
    if (!isMounted()) return;
    if (prevRemoveSaleErrorMessage !== removeSaleErrorMessage && removeSaleErrorMessage) {
      if (isFunction(openErrorModal)) openErrorModal(removeSaleErrorMessage);
    }
  }, [isMounted, prevRemoveSaleErrorMessage, removeSaleErrorMessage, openErrorModal]);

  return (
    <>
      <Button
        minWidth={AppSizes.ButtonMinWidth}
        isLoading={isButtonLoading}
        loadingText={buttonLoadingText}
        borderRadius={'full'}
        onClick={onClickCancelSell}
        disabled={isButtonDisabled}
      >
        <Text>{t('Component:CancelSellButton.Cancel')}</Text>
      </Button>
      <ConfirmCancelSellModal
        isOpen={isOpenConfirmModal}
        onConfirm={onConfirmCancelSell}
        onCancel={onCloseConfirmModal}
      />
    </>
  );
};

export default CancelSellButton;
