import { useEffect, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';

const useCheckInstalledMetaMask = () => {
  const [isInstalled, setIsInstalled] = useState(false);
  useEffect(() => {
    if (MetamaskProvider.checkIsInstalled()) {
      setIsInstalled(true);
    } else {
      setIsInstalled(false);
    }
  }, []);
  return isInstalled;
};

export default useCheckInstalledMetaMask;
