import React, { memo, useMemo } from 'react';
import { Box, Flex, HStack, Image, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@chakra-ui/icons';
import get from 'lodash/get';
import { Colors } from 'src/shared';
import { appUtils, coreUtils, timeUtils } from 'src/common';
import { IFixedPriceSaleNFT, IGeneMetadata, TNFTType } from 'src/gql/types';
import { RecentlyListKey, TRecentlyListKey } from 'src/redux/dashboard/types';
import { FixedPriceSaleLabel } from 'src/components';
import useIsMobile from 'src/hooks/useIsMobile';
import { NFTType } from '../../../globals/constants';

export interface RecentlyListItemProps {
  nftType: TNFTType;
  recentlyListKey: TRecentlyListKey;
  item: IFixedPriceSaleNFT;
  rootPath: string;
}

const RecentlyListItem = (props: RecentlyListItemProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { item, rootPath, nftType, recentlyListKey } = props;
  const hoverBg = useColorModeValue(Colors.light.cardBg, Colors.dark.cardBg);
  const textColor = useColorModeValue(Colors.light.text, Colors.dark.text);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const priceInfo = item.sellPrice;
  const linkToFixedPriceSaleNFTDetail = appUtils.getNFTDetailUrl(item.id, rootPath);
  const imageUrl = useMemo(() => {
    return coreUtils.getNftImage(
      nftType,
      item.tokenId,
      item.templateId,
      !!(item.item?.metadata as IGeneMetadata)?.extractedData,
      (item.item?.metadata as IGeneMetadata)?.extractedData?.variant
    );
  }, [item, nftType]);

  const ownerTextKey =
    recentlyListKey === RecentlyListKey.RECENTLY_SOLD
      ? 'Component:RecentlyList.Header.Buyer'
      : 'Component:RecentlyList.Header.Owner';
  const name = get(item, 'item.metadata.name') || '';
  return (
    <Box w="full" overflow="hidden">
      <Link
        to={{ pathname: linkToFixedPriceSaleNFTDetail, state: { currentFixedPriceSaleNFT: item } }}
      >
        <Flex
          w="full"
          p={[2, 4]}
          rounded="md"
          overflow="hidden"
          bg={'transparent'}
          _hover={{
            bg: hoverBg,
          }}
          alignItems="center"
        >
          <HStack alignItems="center" flex={1} pr={2}>
            {nftType === NFTType.LAND ? (
              <Image {...styles.landImage} src={imageUrl} />
            ) : (
              <Image {...styles.image} src={imageUrl} />
            )}
            <Flex pos="relative" p={0} pl={2} flex={1.4} flexDirection="column">
              <Text fontWeight={700} color={textColor} pb={2}>
                {name}
              </Text>
              <Text
                color={textColor}
                pb={2}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {t('Component:RecentlyList.Info.Quantity', { quantity: item?.amount })}
              </Text>
              {isMobile && (
                <Text color={textColor} pb={2}>
                  <Tooltip label={item.seller?.address}>
                    {`${t(ownerTextKey)}: ${appUtils.shortenHash(item.seller?.address)}`}
                  </Tooltip>
                </Text>
              )}
            </Flex>
            {!isMobile && (
              <Flex pos="relative" p={0} flex={0.8} flexDirection="column">
                <Text color={descriptionColor} pb={2}>
                  {t(ownerTextKey)}
                </Text>
                <Text color={textColor} pb={2}>
                  <Tooltip label={item.seller?.address}>
                    {appUtils.shortenHash(item.seller?.address)}
                  </Tooltip>
                </Text>
              </Flex>
            )}
            <Flex pos="relative" p={0} flex={0.8} flexDirection="column" alignItems={'flex-end'}>
              <FixedPriceSaleLabel
                pb={2}
                data={{
                  price: priceInfo?.origin,
                  priceCurrency: priceInfo?.originalCurrency,
                }}
              />
              <Text
                color={textColor}
                pb={2}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {timeUtils.displayTimeFromNow(item.buyTime)}
              </Text>
            </Flex>
          </HStack>
          <ChevronRightIcon h={8} w={8} color={descriptionColor} mr={[-2, -4]} />
        </Flex>
      </Link>
    </Box>
  );
};

const styles = {
  image: {
    height: '60px',
    objectFit: 'contain' as 'contain',
  },
  landImage: {
    height: '60px',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    border: '1px solid white',
  },
};

export default memo(RecentlyListItem);
