import { Box, Text, VStack, Image, HStack, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SkillType } from '../../../../gql/types';
import { coreUtils } from '../../../../common';

interface SkillPropsType {
  title: string;
  skillType: SkillType;
  klass: number;
  skillIndex: number;
  rarity?: number;
}

const Skill = ({ title, skillType, klass, skillIndex, rarity }: SkillPropsType) => {
  const { t } = useTranslation();
  const imageSrc = coreUtils.getSkillImageUrl(skillType, klass, skillIndex);
  const rarityAttr = useMemo(() => {
    if (rarity) {
      return coreUtils.getAttributesByRarity(rarity);
    }
    return null;
  }, [rarity]);
  const rarityColor = useColorModeValue(rarityAttr?.light, rarityAttr?.dark);

  return (
    <Box p={3} shadow="md" borderWidth="1px" rounded={5} width="100%">
      <HStack>
        <Box shadow="md" borderWidth="3px" borderColor={rarityColor}>
          <Image src={imageSrc} alt="skill" w="60px" />
        </Box>
        <VStack width="100%" alignItems="flex-start">
          <HStack justifyContent="space-between" width="100%">
            <HStack>
              {title && <Text width="fit-content">{title}</Text>}
              <Text>Skill Name</Text>
            </HStack>
            <Text fontWeight="bold" color={rarityColor}>
              {t(rarityAttr?.label || '')}
            </Text>
          </HStack>
          <Text>Skill Description</Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export default Skill;
