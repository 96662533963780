import { Box, HStack, VStack, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ISlimeMetadata } from '../../../../gql/types';
import { SlimeInfoMetadata } from '../../../../common/slimeStatCalculation';
import { Colors } from '../../../../shared';

interface BattleStatPropsType {
  metadata: ISlimeMetadata;
}

const BattleStat = ({ metadata }: BattleStatPropsType) => {
  const { t } = useTranslation();

  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  const fullStatGroup = useMemo(() => {
    return new SlimeInfoMetadata(metadata).fullStatGroup;
  }, [metadata]);

  return (
    <Box w="100%">
      <Text color={descriptionColor} pb={2}>
        {t('Component:SlimeDetails:BattleStat')}
      </Text>
      <Box p={5} shadow="md" borderWidth="1px" rounded={5} width="100%">
        <HStack justify="space-around" w="100%">
          <VStack align="start">
            <Text>{`HP: ${fullStatGroup.hp.value.toFixed(1)}`}</Text>
            <Text>{`ATK: ${fullStatGroup.atk.value.toFixed(1)}`}</Text>
            <Text>{`DEF: ${fullStatGroup.def.value.toFixed(1)}`}</Text>
            <Text>{`Block: ${fullStatGroup.block.value.toFixed(1)}`}</Text>
          </VStack>
          <VStack align="start">
            <Text>{`Critical: ${fullStatGroup.crit.value.toFixed(1)}`}</Text>
            <Text>{`Critical Dmg: ${fullStatGroup.critdmg.value.toFixed(1)}`}</Text>
            <Text>{`Evade: ${fullStatGroup.evade.value.toFixed(1)}`}</Text>
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};

export default BattleStat;
