import { Dispatch } from 'redux';
import * as GQLApi from 'src/gql';
import {
  IGetUserActivitiesResult,
  IGetUserActivitiesVariable,
} from '../../gql/user-activity/types';
import * as Types from './types';

const updateUserActivitiesState = (payload: IGetUserActivitiesResult) => {
  return {
    type: Types.UPDATE_USER_ACTIVITIES_STATE,
    payload,
  };
};

export const getUserActivities = (
  variables: IGetUserActivitiesVariable,
  callback?: (success: boolean) => any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(updateUserActivitiesState({ records: [], total: 0 }));
      const result = await GQLApi.getUserActivities(variables);
      dispatch(updateUserActivitiesState(result));
      callback && callback(true);
    } catch (err) {
      callback && callback(false);
      throw err;
    }
  };
};
