import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { WalletAction } from 'src/redux';

const useOnConnectedWithMetaMask = () => {
  const dispatch = useDispatch();
  return useCallback(
    (metaMaskAddress: string) => {
      dispatch(WalletAction.connectedWithMetaMask(metaMaskAddress));
    },
    [dispatch]
  );
};

export default useOnConnectedWithMetaMask;
