import { Box, HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../shared';
import InfoItem from '../InfoItem';
import { GeneClassLabel, RarityLabel } from '../../../../components';

interface PartPropsType {
  title: string;
  children: ReactNode;
  klass: number;
  rarity: number;
}

const Part = ({ title, children, klass, rarity }: PartPropsType) => {
  const { t } = useTranslation();
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  return (
    <VStack align="start" mt="10px">
      <HStack justify="space-between" w="100%">
        <InfoItem title={t('Component:SlimeDetails:Part:Part')}>
          <Text color={descriptionColor} fontWeight="bold">
            {title}
          </Text>
        </InfoItem>
        <InfoItem title={t('Term:GeneClass')}>
          <GeneClassLabel geneClass={klass} />
        </InfoItem>
        <InfoItem title={t('Term:Rarity')}>
          <RarityLabel rarity={rarity} />
        </InfoItem>
      </HStack>
      <Box p={5} shadow="md" borderWidth="1px" rounded={5} width="100%">
        <HStack justify="space-around" w="100%">
          {children}
        </HStack>
      </Box>
    </VStack>
  );
};

export default Part;
