import { SlimeClass } from '../gql/types';
// sp_c5_r1_03
export const SlimeSkillIds = {
  [SlimeClass.Crusader]: [
    null,
    null,
    'sa_c2_r1_01',
    'sp_c2_r1_01',
    'sa_c2_r1_02',
    'sp_c2_r1_02',
    'sa_c2_r1_03',
    'sp_c2_r1_03',
    'sa_c2_r1_04',
    'sp_c2_r1_04',

    'sa_c2_r2_01',
    'sp_c2_r2_01',
    'sa_c2_r2_02',
    'sp_c2_r2_02',
    'sa_c2_r2_03',
    'sp_c2_r2_03',

    'sa_c2_r3_01',
    'sp_c2_r3_01',
    'sa_c2_r3_02',
    'sp_c2_r3_02',
    'sa_c2_r3_03',
    'sp_c2_r3_03',

    'sa_c2_r4_01',
    'sp_c2_r4_01',
    'sa_c2_r4_02',
    'sp_c2_r4_02',
    'sa_c2_r4_03',
    'sp_c2_r4_03',

    'sa_c2_r5_01',
    'sp_c2_r5_01',
    'sa_c2_r5_02',
    'sp_c2_r5_02',
  ],

  [SlimeClass.Wizard]: [
    null,
    null,
    'sa_c3_r1_01',
    'sp_c3_r1_01',
    'sa_c3_r1_02',
    'sp_c3_r1_02',
    'sa_c3_r1_03',
    'sp_c3_r1_03',
    'sa_c3_r1_04',
    'sp_c3_r1_04',

    'sa_c3_r2_01',
    'sp_c3_r2_01',
    'sa_c3_r2_02',
    'sp_c3_r2_02',
    'sa_c3_r2_03',
    'sp_c3_r2_03',

    'sa_c3_r3_01',
    'sp_c3_r3_01',
    'sa_c3_r3_02',
    'sp_c3_r3_02',
    'sa_c3_r3_03',
    'sp_c3_r3_03',

    'sa_c3_r4_01',
    'sp_c3_r4_01',
    'sa_c3_r4_02',
    'sp_c3_r4_02',
    'sa_c3_r4_03',
    'sp_c3_r4_03',

    'sa_c3_r5_01',
    'sp_c3_r5_01',
    'sa_c3_r5_02',
    'sp_c3_r5_02',
  ],

  [SlimeClass.Assassin]: [
    null,
    null,
    'sa_c4_r1_01',
    'sp_c4_r1_01',
    'sa_c4_r1_02',
    'sp_c4_r1_02',
    'sa_c4_r1_03',
    'sp_c4_r1_03',
    'sa_c4_r1_04',
    'sp_c4_r1_04',

    'sa_c4_r2_01',
    'sp_c4_r2_01',
    'sa_c4_r2_02',
    'sp_c4_r2_02',
    'sa_c4_r2_03',
    'sp_c4_r2_03',

    'sa_c4_r3_01',
    'sp_c4_r3_01',
    'sa_c4_r3_02',
    'sp_c4_r3_02',
    'sa_c4_r3_03',
    'sp_c4_r3_03',

    'sa_c4_r4_01',
    'sp_c4_r4_01',
    'sa_c4_r4_02',
    'sp_c4_r4_02',
    'sa_c4_r4_03',
    'sp_c4_r4_03',

    'sa_c4_r5_01',
    'sp_c4_r5_01',
    'sa_c4_r5_02',
    'sp_c4_r5_02',
  ],

  [SlimeClass.Saint]: [
    null,
    null,
    'sa_c5_r1_01',
    'sp_c5_r1_01',
    'sa_c5_r1_02',
    'sp_c5_r1_02',
    'sa_c5_r1_03',
    'sp_c5_r1_03',
    'sa_c5_r1_04',
    'sp_c5_r1_04',

    'sa_c5_r2_01',
    'sp_c5_r2_01',
    'sa_c5_r2_02',
    'sp_c5_r2_02',
    'sa_c5_r2_03',
    'sp_c5_r2_03',

    'sa_c5_r3_01',
    'sp_c5_r3_01',
    'sa_c5_r3_02',
    'sp_c5_r3_02',
    'sa_c5_r3_03',
    'sp_c5_r3_03',

    'sa_c5_r4_01',
    'sp_c5_r4_01',
    'sa_c5_r4_02',
    'sp_c5_r4_02',
    'sa_c5_r4_03',
    'sp_c5_r4_03',

    'sa_c5_r5_01',
    'sp_c5_r5_01',
    'sa_c5_r5_02',
    'sp_c5_r5_02',
  ],

  [SlimeClass.Shaman]: [
    null,
    null,
    'sa_c6_r1_01',
    'sp_c6_r1_01',
    'sa_c6_r1_02',
    'sp_c6_r1_02',
    'sa_c6_r1_03',
    'sp_c6_r1_03',
    'sa_c6_r1_04',
    'sp_c6_r1_04',

    'sa_c6_r2_01',
    'sp_c6_r2_01',
    'sa_c6_r2_02',
    'sp_c6_r2_02',
    'sa_c6_r2_03',
    'sp_c6_r2_03',

    'sa_c6_r3_01',
    'sp_c6_r3_01',
    'sa_c6_r3_02',
    'sp_c6_r3_02',
    'sa_c6_r3_03',
    'sp_c6_r3_03',

    'sa_c6_r4_01',
    'sp_cru_r4_01',
    'sa_c6_r4_02',
    'sp_c6_r4_02',
    'sa_c6_r4_03',
    'sp_c6_r4_03',

    'sa_c6_r5_01',
    'sp_c6_r5_01',
    'sa_c6_r5_02',
    'sp_c6_r5_02',
  ],
};
