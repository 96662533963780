import { Link, DeepPartial, Td, Tooltip, Tr, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { appUtils, coreUtils, timeUtils } from 'src/common';
import { IUserActivity } from 'src/gql/user-activity/types';
import { Label } from 'src/components';
import { TNFTType } from 'src/gql/types';
import React from 'react';
import { getActivityLabel } from '../common';
import { styles } from '../styles';
import UserActivityRowItem from './UserActivityRowItem';

interface Props {
  activity: DeepPartial<IUserActivity>;
  currentAccountAddress?: string;
}

const UserActivityRow = ({ activity, currentAccountAddress }: Props) => {
  const { t } = useTranslation();
  const time = timeUtils.displayTimeFromNow(activity?.time);
  const transactionUrl = appUtils.getTransactionExplorerLink(activity.transaction?.transactionHash);
  const label = getActivityLabel(activity, currentAccountAddress);
  const nftName = coreUtils.getNFTTypeNameByNftType(activity?.item?.type as TNFTType);
  const linkToNFTList = coreUtils.getPathByNFTType(activity?.item?.type as TNFTType);
  return (
    <Tr>
      <Td {...styles.column}>
        <Tooltip label={activity.transaction?.transactionHash}>
          <Link href={transactionUrl} isExternal>
            {appUtils.shortenHash(activity.transaction?.transactionHash || '')}{' '}
            <ExternalLinkIcon mx="2px" mb="4px" />
          </Link>
        </Tooltip>
      </Td>
      <Td {...styles.column}>{activity.item && <UserActivityRowItem item={activity.item} />}</Td>
      <Td {...styles.column}>
        <Link href={linkToNFTList} fontWeight={'bold'}>
          <Label
            label={t(nftName)}
            fontSize="sm"
            width="fit-content"
            px={3}
            py={1}
            borderRadius={5}
          />
        </Link>
      </Td>
      <Td {...styles.column} maxW={{ base: '100px', md: '140px' }}>
        <Tooltip label={t(label)}>
          <Text isTruncated>{t(label)}</Text>
        </Tooltip>
      </Td>
      <Td {...styles.column}>{Boolean(activity.time) && time}</Td>
    </Tr>
  );
};

export default UserActivityRow;
