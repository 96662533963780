import { useCallback, useState } from 'react';
import MetamaskProvider, { metaMaskCommon } from 'src/metamask-provider';
import { ISaleInfoFromMetaMask } from 'src/metamask-provider/type';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';

const useGetFixedPriceSaleInfoBySaleId = (nftType: TNFTType) => {
  const [isGettingSaleInfoBySaleId, setIsGettingSaleInfoBySaleId] = useState<boolean | undefined>();
  const [saleInfo, setSaleInfo] = useState<ISaleInfoFromMetaMask | undefined>();
  const [currentSaleId, setCurrentSaleId] = useState<string | undefined>();
  const getFixedPriceSaleInfoBySaleId = useCallback(
    (saleId?: string) => {
      setCurrentSaleId(saleId);
      if (saleId) {
        setIsGettingSaleInfoBySaleId(true);
        const nftContractName = coreUtils.getNFTContractNameByNftType(nftType);
        const nftMarketContractName =
          metaMaskCommon.getMarketContractNameByNFTContractName(nftContractName);
        MetamaskProvider.getSaleInfoBySaleId(nftMarketContractName, saleId)
          .then((saleInfo) => {
            return setSaleInfo(saleInfo);
          })
          .catch(console.error)
          .finally(() => setIsGettingSaleInfoBySaleId(false));
      } else {
        setSaleInfo(undefined);
      }
    },
    [nftType, setIsGettingSaleInfoBySaleId]
  );

  return [
    isGettingSaleInfoBySaleId,
    currentSaleId ? saleInfo : undefined,
    getFixedPriceSaleInfoBySaleId,
  ];
};

export default useGetFixedPriceSaleInfoBySaleId;
