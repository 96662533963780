import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import BN from 'bn.js';
import {
  CryptoDecimal,
  MaxPrecision,
  NFTMenuLabel,
  NFTType,
  SortByType,
} from 'src/globals/constants';
import {
  ADDRESS_EXPLORER_URL,
  FANTASY_GACHA_BOX_NFT_CONTRACT_ADDRESS,
  FANTASY_GENE_NFT_CONTRACT_ADDRESS,
  FANTASY_LAND_NFT_CONTRACT_ADDRESS,
  FANTASY_TOOL_MATERIAL_NFT_CONTRACT_ADDRESS,
  FANTASY_TOOL_NFT_CONTRACT_ADDRESS,
  TRANSACTION_EXPLORER_URL,
} from 'src/globals/configs';
import { Resources } from '../shared';

export const parseDisplayPriceFromNumberWithDecimal = (numberWithDecimal: string): number => {
  const salePriceBN = new BN(numberWithDecimal);
  const cryptoDecimalBN = new BN(10).pow(new BN(CryptoDecimal));
  const displayPriceBN = salePriceBN.mul(new BN(10).pow(new BN(MaxPrecision))).div(cryptoDecimalBN);
  return Number(displayPriceBN.toString()) / Math.pow(10, MaxPrecision);
};

export const parseNumberWithDecimalFromDisplayPrice = (displayPrice: string): string => {
  const floatNumber = parseFloat(displayPrice);
  const int = Math.round(floatNumber * Math.pow(10, MaxPrecision));
  const priceBN = new BN(int).mul(new BN(10).pow(new BN(CryptoDecimal - MaxPrecision)));
  return priceBN.toString();
};

export const getSortByType = (
  sortType: string
): {
  key: string;
  order: 'asc' | 'desc';
} => {
  return get(SortByType, sortType as string);
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getNFTDetailUrl = (tokenId: number | string, rootPath: string): string => {
  return `${rootPath}/${tokenId}`;
};

export const isAddressEqual = (address?: string, other?: string): boolean => {
  return isEqual((address || '').toUpperCase(), (other || '').toUpperCase());
};

export const shortenHash = (hashStr: string, length = 6): string => {
  const first = hashStr.substr(0, 4);
  const last = hashStr.substring(hashStr.length - length);
  return `${first}...${last}`;
};

export const getAddressExplorerLink = (address?: string): string => {
  return `${ADDRESS_EXPLORER_URL}/${address}`;
};

export const getTransactionExplorerLink = (hashStr?: string): string => {
  return `${TRANSACTION_EXPLORER_URL}/${hashStr}`;
};

export const getNFTContractAddress = (nftType: string): string => {
  switch (nftType) {
    case NFTType.LAND:
      return FANTASY_LAND_NFT_CONTRACT_ADDRESS;
    case NFTType.MINING_TOOL:
      return FANTASY_TOOL_NFT_CONTRACT_ADDRESS;
    case NFTType.GENE:
      return FANTASY_GENE_NFT_CONTRACT_ADDRESS;
    case NFTType.GACHA:
      return FANTASY_GACHA_BOX_NFT_CONTRACT_ADDRESS;
    case NFTType.TOOL_MATERIAL:
      return FANTASY_TOOL_MATERIAL_NFT_CONTRACT_ADDRESS;
  }
  return '';
};

export const displayPriceWithCurrency = (
  price?: number,
  currency = 'FTC',
  maxDigits = 5
): string | undefined => {
  if (isNil(price)) return undefined;

  let maxPrecision = MaxPrecision;
  if (price > 1000) {
    maxPrecision = 0;
  } else if (price > 100) {
    maxPrecision = 2;
  } else if (price > 1) {
    maxPrecision = 3;
  }

  const options = {
    style: !isEmpty(currency) ? 'currency' : 'decimal',
    currency: !isEmpty(currency) ? currency : undefined,
    notation: maxDigits > 0 && price > Math.pow(10, maxDigits) ? 'compact' : 'standard',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxPrecision,
  };
  //@ts-ignore
  return price.toLocaleString('en', options);
};

export const displayPriceWithCurrencyFromNumberWithDecimal = (
  priceStr?: string,
  currency = 'FTC',
  maxDigits = 5
): string | undefined => {
  if (isEmpty(priceStr)) return undefined;
  const price = parseDisplayPriceFromNumberWithDecimal(priceStr as string);
  return displayPriceWithCurrency(price, currency, maxDigits);
};

export const getNFTIconByLabel = (label?: string): string | undefined => {
  switch (label) {
    case NFTMenuLabel.GENE:
      return Resources.images.gene;
    case NFTMenuLabel.SLIME:
      return Resources.images.slime;
    case NFTMenuLabel.LAND:
      return Resources.svgs.land;
    case NFTMenuLabel.MINING_TOOL:
      return Resources.svgs.miningTool;
    case NFTMenuLabel.GACHA_BOX:
      return Resources.svgs.gachaBox;
    case NFTMenuLabel.TOOL_MATERIAL:
      return Resources.svgs.toolMaterial;
  }
  return undefined;
};

export const convertStringToNumber = (str?: string) => {
  const value = Number(str);
  return isNaN(value) ? 0 : value;
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email = '') => emailRegex.test(email);
