import { useEffect } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import useOnConnectedWithMetaMask from './useOnConnectedWithMetaMask';
import useOnDisconnectedWithMetaMask from './useOnDisconnectedWithMetaMask';

const useWalletConnectListener = () => {
  const onConnectedWithMetaMask = useOnConnectedWithMetaMask();
  const onDisconnectedWithMetaMask = useOnDisconnectedWithMetaMask();
  useEffect(() => {
    MetamaskProvider.checkIsMetaMaskConnected()
      .then((isConnected) => {
        if (isConnected) {
          return MetamaskProvider.getAccountAddress();
        }
      })
      .then((accountAddress) => {
        if (accountAddress) {
          onConnectedWithMetaMask(accountAddress);
        } else {
          onDisconnectedWithMetaMask();
        }
      })
      .catch(() => {
        onDisconnectedWithMetaMask();
      });
    MetamaskProvider.addListenerAccountChange((newAddress) => {
      if (newAddress) {
        onConnectedWithMetaMask(newAddress);
      } else {
        onDisconnectedWithMetaMask();
      }
    });
  }, [onConnectedWithMetaMask, onDisconnectedWithMetaMask]);
  return null;
};

export default useWalletConnectListener;
