import { gql } from '@apollo/client';
import includes from 'lodash/includes';
import { NFTType } from 'src/globals/constants';
import { TNFTType } from '../types';
import { getNFTMetadataFragment } from './nftMetadata';

const NFTTypesWithMetadata = [NFTType.MINING_TOOL, NFTType.GENE, NFTType.SLIME, NFTType.LAND];

export const FIXED_PRICE_SALE_ITEM_KEY = 'fixedPriceSaleItem';

export const getFixedPriceSaleItemFragment = (
  nftType: TNFTType,
  key = FIXED_PRICE_SALE_ITEM_KEY
) => {
  if (includes(NFTTypesWithMetadata, nftType))
    return getFixedPriceSaleItemFragmentWithMetadata(nftType, key);
  return getFixedPriceSaleItemFragmentWithoutMetadata(nftType, key);
};

export const getFixedPriceSaleItemFragmentWithoutMetadata = (
  nftType: TNFTType,
  key = FIXED_PRICE_SALE_ITEM_KEY
) =>
  gql`
  fragment ${key} on NFT {
     tokenId
     contractAddress
     format
     type
  }
`;
export const getFixedPriceSaleItemFragmentWithMetadata = (
  nftType: TNFTType,
  key = FIXED_PRICE_SALE_ITEM_KEY
) =>
  gql`
  ${getNFTMetadataFragment(nftType, 'nftMetadataFragment')}  
  fragment ${key} on NFT {
     tokenId
     templateId
     contractAddress
     format
     type
     metadata {
       ...nftMetadataFragment
     }
  }
`;
