import { Dispatch } from 'redux';
import MetamaskProvider from '../../metamask-provider';
import * as Types from './types';

export const connectedWithMetaMask = (metaMaskWalletAddress: string) => {
  return {
    type: Types.CONNECTED_METAMASK_SUCCESS,
    payload: {
      metaMaskWalletAddress,
    },
  };
};

export const disconnectedWithMetaMask = () => {
  return {
    type: Types.DISCONNECTED_METAMASK_SUCCESS,
  };
};

const getFTCBalanceRequest = () => {
  return {
    type: Types.GET_FTC_BALANCE_REQUEST,
  };
};

const getFTCBalanceSuccess = (balance: string) => {
  return {
    type: Types.GET_FTC_BALANCE_SUCCESS,
    payload: {
      balance,
    },
  };
};

const getFTCBalanceFailure = (errorMessage: string) => {
  return {
    type: Types.GET_FTC_BALANCE_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getFTCBalance = () => {
  return (dispatch: Dispatch) => {
    dispatch(getFTCBalanceRequest());
    return MetamaskProvider.getFTCBalance()
      .then((balance) => {
        return dispatch(getFTCBalanceSuccess(balance));
      })
      .catch((e) => dispatch(getFTCBalanceFailure(e.message)));
  };
};
