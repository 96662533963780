import {
  Text,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppSizes, Colors, Resources } from 'src/shared';

interface IMetaMaskNotInstallProps {
  isOpen: boolean;
  onClose: () => void;
}

const MetaMaskNotInstallModal = ({ isOpen, onClose }: IMetaMaskNotInstallProps) => {
  const { t } = useTranslation();
  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );
  const secondaryButtonBackgroundColor = useColorModeValue(
    Colors.light.secondaryBg,
    Colors.dark.secondaryBg
  );
  return (
    <Modal size={'sm'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor={modalBackgroundColor}>
        <ModalHeader textAlign={'center'}>
          {t('Component:ModalMetaMaskNotInstall.Title')}
        </ModalHeader>
        <ModalBody textAlign={'center'}>
          <Flex flexDirection="column" alignItems="center">
            <Image boxSize="50%" src={Resources.svgs.metamask} marginRight={3} />
            <Text mt={5}>
              {t('Component:ModalMetaMaskNotInstall.Description')}
              <a className="download-link" href={'https://metamask.io/download.html'}>
                {t('Here').toLowerCase()}
              </a>
            </Text>
          </Flex>
        </ModalBody>
        <ModalCloseButton />
        <ModalFooter mx={'auto'}>
          <Button
            borderRadius="full"
            minWidth={AppSizes.ButtonMinWidth}
            onClick={onClose}
            backgroundColor={secondaryButtonBackgroundColor}
          >
            {t('GotIt')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MetaMaskNotInstallModal;
