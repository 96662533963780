import { useCallback, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import { useSelector } from 'react-redux';
import BN from 'bn.js';
import { useTranslation } from 'react-i18next';
import { RootReducerState } from '../../../redux';

const useBuyNFTFromFixedPriceSaleMarket = (nftType: TNFTType, currentPrice?: string) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [isBuyingNFTFromFixedPriceSaleMarket, setIsBuyingNFTFromFixedPriceSaleMarket] = useState<
    boolean | undefined
  >();
  const ftcBalance = useSelector((state: RootReducerState) => state.wallet.FTCBalance);

  const onBuyNFTFromFixedPriceSaleMarket = useCallback(
    async (saleId: string, onBuySuccess?: (txid: string) => void) => {
      try {
        setError(undefined);
        setIsBuyingNFTFromFixedPriceSaleMarket(true);
        //Check enough ftc to process buy
        if (currentPrice && ftcBalance) {
          const ftcBalanceBN = new BN(ftcBalance);
          const currentPriceBN = new BN(currentPrice);
          if (currentPriceBN.gt(ftcBalanceBN)) {
            // Move setError to the next tick
            setTimeout(() => setError(t('Component:BuyButton:NotEnoughFTCMessage')));
            return;
          }
        }
        const receipt = await MetamaskProvider.buyNFTFromFixedSaleMarket(
          coreUtils.getNFTContractNameByNftType(nftType),
          saleId
        );
        if (onBuySuccess) {
          onBuySuccess(receipt.transactionHash);
        }
      } catch (error: any) {
        setError(error.message);
        console.error(error);
      } finally {
        setIsBuyingNFTFromFixedPriceSaleMarket(false);
      }
    },
    [currentPrice, ftcBalance, nftType, t]
  );

  return [isBuyingNFTFromFixedPriceSaleMarket, onBuyNFTFromFixedPriceSaleMarket, error];
};

export default useBuyNFTFromFixedPriceSaleMarket;
