import get from 'lodash/get';
import { TNFTType } from 'src/gql/types';
import * as Types from './types';
import { RecentlyActionKey, TRecentlyActionKey, TRecentlyListKey } from './types';
import { getRecentlyListDataKey } from './common';
import { initialRecentlyState } from './constants';

export const updateRecentlyList = (
  oldState: Types.DashboardState,
  recentlyListKey: TRecentlyListKey,
  actionKey: TRecentlyActionKey,
  payload: any
) => {
  const recentlyListDataKey = getRecentlyListDataKey(payload.nftType as TNFTType);
  const oldRecentlyState = oldState[recentlyListKey] || {};
  const oldRecentlyListDataState = get(oldState, recentlyListDataKey, initialRecentlyState);
  const recentlyState = getRecentlyListDataState(oldRecentlyListDataState, actionKey, payload);
  return {
    ...oldState,
    [recentlyListKey]: {
      ...oldRecentlyState,
      [recentlyListDataKey]: recentlyState,
    },
  };
};

export const getRecentlyListDataState = (
  oldRecentlyListDataState: Types.RecentlyListDataState,
  actionKey: TRecentlyActionKey,
  payload: any
): Types.RecentlyListDataState => {
  let isGetting = false;
  let gettingSuccess = false;
  let gettingMessage = '';
  let data = {};
  switch (actionKey) {
    case RecentlyActionKey.RECENTLY_REQUEST:
      isGetting = true;
      break;
    case RecentlyActionKey.RECENTLY_SUCCESS:
      const fixedPriceSaleNFTs = get(payload, 'records', []);
      const total = get(payload, 'total', 0);
      data = {
        listOfFixedPriceSaleNFTs: fixedPriceSaleNFTs,
        totalFixedPriceSaleNFTs: total,
      };
      gettingSuccess = true;
      break;
    case RecentlyActionKey.RECENTLY_FAILURE:
      gettingMessage = payload.errorMessage;
      break;
  }
  return {
    ...oldRecentlyListDataState,
    ...data,
    isGetting,
    gettingSuccess,
    gettingMessage,
  };
};
