import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ISlimeMetadata } from 'src/gql/types';
import Stat from './Stat';
import Skills from './Skills';
import Parts from './Parts';

interface SlimeAboutsProps {
  metadata: ISlimeMetadata;
}

const SlimeAbouts = ({ metadata }: SlimeAboutsProps) => {
  const { t } = useTranslation();

  return (
    <Tabs variant="enclosed" p="20px">
      <TabList>
        <Tab>{t('Component:SlimeDetails:Stats')}</Tab>
        <Tab>{t('Component:SlimeDetails:Skills')}</Tab>
        <Tab>{t('Component:SlimeDetails:Parts')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stat metadata={metadata} />
        </TabPanel>
        <TabPanel>
          <Skills metadata={metadata} />
        </TabPanel>
        <TabPanel>
          <Parts metadata={metadata} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default React.memo(SlimeAbouts);
