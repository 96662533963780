import * as Types from './types';

const initialState: Types.WalletState = {
  isMetaMaskConnected: false,
  metaMaskWalletAddress: undefined,
  isGettingFTCBalance: false,
  FTCBalance: undefined,
  getFTCBalanceErrorMessage: undefined,
};

const walletReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Types.CONNECTED_METAMASK_SUCCESS:
      return {
        ...state,
        isMetaMaskConnected: true,
        metaMaskWalletAddress: action.payload.metaMaskWalletAddress,
      };
    case Types.DISCONNECTED_METAMASK_SUCCESS:
      return {
        ...state,
        isMetaMaskConnected: false,
        metaMaskWalletAddress: undefined,
      };
    case Types.GET_FTC_BALANCE_REQUEST:
      return {
        ...state,
        isGettingFTCBalance: true,
        getFTCBalanceErrorMessage: undefined,
      };
    case Types.GET_FTC_BALANCE_SUCCESS:
      return {
        ...state,
        isGettingFTCBalance: false,
        FTCBalance: action.payload.balance,
        getFTCBalanceErrorMessage: undefined,
      };
    case Types.GET_FTC_BALANCE_FAILURE:
      return {
        ...state,
        isGettingFTCBalance: false,
        FTCBalance: undefined,
        getFTCBalanceErrorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
};

export default walletReducer;
