import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isFunction from 'lodash/isFunction';
import { RootReducerState } from '../redux';
import useUpdateCustomerInfo from './useUpdateCustomerInfo';
import useAutoCreateUserByWalletAddress from './useAutoCreateUserByWalletAddress';

const useAutoUpdateCustomerInfo = () => {
  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );
  const [, , updateCustomerInfo] = useUpdateCustomerInfo();
  const [, , registerUserWithAccountService] = useAutoCreateUserByWalletAddress();
  useEffect(() => {
    if (metaMaskWalletAddress && isFunction(updateCustomerInfo)) {
      updateCustomerInfo(metaMaskWalletAddress, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }).catch(console.error);
    }
    if (metaMaskWalletAddress && isFunction(registerUserWithAccountService)) {
      registerUserWithAccountService(metaMaskWalletAddress).catch(console.error);
    }
  }, [metaMaskWalletAddress, registerUserWithAccountService, updateCustomerInfo]);
};

export default useAutoUpdateCustomerInfo;
