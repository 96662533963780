import React, { memo } from 'react';
import { Flex, TabList, TabPanel, TabPanels, Tabs, useColorModeValue } from '@chakra-ui/react';
import { Colors } from 'src/shared';
import { RecentlyListKey } from 'src/redux/dashboard/types';
import { NFTTabs } from '../constants';
import RecentlyList from './RecentlyList';
import RecentlyTab from './RecentlyTab';

const RecentlyListedAndSold = () => {
  const borderColor = useColorModeValue(Colors.dark.divider, Colors.dark.divider);

  return (
    <Flex {...styles.container}>
      <Tabs {...styles.tabContainer} borderColor={borderColor}>
        <TabList overflow="hidden" overflowX="scroll">
          {NFTTabs.map((item) => (
            <RecentlyTab key={item.label} item={item} />
          ))}
        </TabList>

        <TabPanels overflow="hidden">
          {NFTTabs.map((item) => (
            <TabPanel key={item.nftType}>
              <Flex direction={{ base: 'column', lg: 'row' }} gridGap={5}>
                <RecentlyList
                  nftType={item.nftType}
                  recentlyListKey={RecentlyListKey.RECENTLY_LISTED}
                />
                <RecentlyList
                  nftType={item.nftType}
                  recentlyListKey={RecentlyListKey.RECENTLY_SOLD}
                />
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default memo(RecentlyListedAndSold);

const styles = {
  container: {
    flex: { base: 'inherit' as 'inherit', md: 1 },
    paddingTop: 4,
    flexDirection: 'column' as 'column',
    position: 'relative' as 'relative',
    overflow: 'hidden',
  },
  tabContainer: {
    flex: 1,
    marginTop: 4,
    borderRadius: 'lg',
    borderWidth: 1,
    borderStyle: 'solid',
    position: 'relative' as 'relative',
    overflow: 'hidden',
  },
  tab: {
    minWidth: '120px',
    p: 2,
    minHeight: '50px',
    fontWeight: 700,
    fontSize: 'md',
    borderBottom: '4px solid',
    _focus: {
      boxShadow: 'none' as 'none',
    },
  },
};
