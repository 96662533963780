import { gql } from '@apollo/client';
import includes from 'lodash/includes';
import { NFTType } from 'src/globals/constants';
import { getGQLNFTDataType } from '../common';
import { TNFTType } from '../types';
import { getNFTMetadataFragment } from './nftMetadata';

const NFTTypesWithMetadata = [NFTType.MINING_TOOL, NFTType.GENE, NFTType.LAND, NFTType.SLIME];

export const NFT_KEY = 'nft';

export const getNFTFragment = (nftType: TNFTType, key = NFT_KEY) => {
  if (includes(NFTTypesWithMetadata, nftType)) return getNFTFragmentWithMetadata(nftType, key);
  return getNFTFragmentWithoutMetadata(nftType, key);
};

export const getNFTFragmentWithoutMetadata = (nftType: TNFTType, key = NFT_KEY) =>
  gql`
  fragment ${key} on ${getGQLNFTDataType(nftType)} {
     tokenId
     templateId
     owner
     totalAmount
     onSaleAmount
     format
     type
  }
`;
export const getNFTFragmentWithMetadata = (nftType: TNFTType, key = NFT_KEY) =>
  gql`
  ${getNFTMetadataFragment(nftType, 'nftMetadataFragment')}  
  fragment ${key} on ${getGQLNFTDataType(nftType)} {
     tokenId
     templateId
     owner
     totalAmount
     onSaleAmount
     onLockAmount
     lockMeta {
       lockBy
     }
     format
     type
     metadata {
      ...nftMetadataFragment
     }
  }
`;
