import React from 'react';
import { Grid, GridItem, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../hooks/useIsMobile';
import useApprovalSetting from '../hooks/useApprovalSetting';
import ApprovalSettingsSection from './ApprovalSettingsSection';
import ApprovalSettingsStatus from './ApprovalSettingsStatus';

interface ApprovalSettingsPanelProps {}

const ApprovalSettingsPanel = (props: ApprovalSettingsPanelProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  // Mining Tool
  const {
    isLoading: isLoadingApprovalStatusFTCToMiningToolMarket,
    hasApproved: hasApprovedFTCToMiningToolMarket,
    setApprovalForAll: setApprovalForAllFTCToMiningTool,
  } = useApprovalSetting('FantasyToolNFTContract', true);

  const {
    isLoading: isLoadingApprovalStatusMiningToolToMiningToolMarket,
    hasApproved: hasApprovedMiningToolToMiningToolMarket,
    setApprovalForAll: setApprovalForAllMiningToolToMiningToolMarket,
  } = useApprovalSetting('FantasyToolNFTContract');

  // Tool Material
  const {
    isLoading: isLoadingApprovalStatusFTCToToolMaterialMarket,
    hasApproved: hasApprovedFTCToToolMaterialMarket,
    setApprovalForAll: setApprovalForAllFTCToToolMaterialMarket,
  } = useApprovalSetting('FantasyToolMaterialNFTContract', true);

  const {
    isLoading: isLoadingApprovalStatusToolMaterialToToolMaterialMarket,
    hasApproved: hasApprovedToolMaterialToToolMaterialMarket,
    setApprovalForAll: setApprovalForAllToolMaterialToToolMaterialMarket,
  } = useApprovalSetting('FantasyToolMaterialNFTContract');

  // Gene
  const {
    isLoading: isLoadingApprovalStatusFTCToGeneMarket,
    hasApproved: hasApprovedFTCToGeneMarket,
    setApprovalForAll: setApprovalForAllFTCToGeneMarket,
  } = useApprovalSetting('FantasyGeneNFTContract', true);

  const {
    isLoading: isLoadingApprovalStatusGeneToGeneMarket,
    hasApproved: hasApprovedGeneToGeneMarket,
    setApprovalForAll: setApprovalForAllGeneToGeneMarket,
  } = useApprovalSetting('FantasyGeneNFTContract');

  // Slime
  const {
    isLoading: isLoadingApprovalStatusFTCToSlimeMarket,
    hasApproved: hasApprovedFTCToSlimeMarket,
    setApprovalForAll: setApprovalForAllFTCToSlimeMarket,
  } = useApprovalSetting('FantasySlimeNFTContract', true);

  const {
    isLoading: isLoadingApprovalStatusSlimeToSlimeMarket,
    hasApproved: hasApprovedSlimeToSlimeMarket,
    setApprovalForAll: setApprovalForAllSlimeToSlimeMarket,
  } = useApprovalSetting('FantasySlimeNFTContract');

  // Land
  const {
    isLoading: isLoadingApprovalStatusFTCToLandMarket,
    hasApproved: hasApprovedFTCToLandMarket,
    setApprovalForAll: setApprovalForAllFTCToLandMarket,
  } = useApprovalSetting('FantasyLandNFTContract', true);

  const {
    isLoading: isLoadingApprovalStatusLandToLandMarket,
    hasApproved: hasApprovedLandToLandMarket,
    setApprovalForAll: setApprovalForAllLandToLandMarket,
  } = useApprovalSetting('FantasyLandNFTContract');

  // Gacha Box
  const {
    isLoading: isLoadingApprovalStatusFTCToGachaBoxMarket,
    hasApproved: hasApprovedFTCToGachaBoxMarket,
    setApprovalForAll: setApprovalForAllFTCToGachaBoxMarket,
  } = useApprovalSetting('FantasyGachaBoxNFTContract', true);

  const {
    isLoading: isLoadingApprovalStatusGachaBoxToGachaBoxMarket,
    hasApproved: hasApprovedGachaBoxToGachaBoxMarket,
    setApprovalForAll: setApprovalForAllGachaBoxToGachaBoxMarket,
  } = useApprovalSetting('FantasyGachaBoxNFTContract');

  return (
    <Center>
      <Grid templateColumns={`repeat(${isMobile ? 1 : 2}, 1fr)`} maxWidth="1024px">
        <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:MiningToolMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToMiningToolMarketDescription')}
              isLoading={isLoadingApprovalStatusFTCToMiningToolMarket}
              checked={hasApprovedFTCToMiningToolMarket}
              onChange={setApprovalForAllFTCToMiningTool}
            />
            <ApprovalSettingsStatus
              title={t(
                'Component:ApprovalSettings:ApprovalMiningToolToMiningToolMarketDescription'
              )}
              isLoading={isLoadingApprovalStatusMiningToolToMiningToolMarket}
              checked={hasApprovedMiningToolToMiningToolMarket}
              onChange={setApprovalForAllMiningToolToMiningToolMarket}
            />
          </ApprovalSettingsSection>
        </GridItem>
        <GridItem>
          <ApprovalSettingsSection
            sectionTitle={t('Component:ApprovalSettings:ToolMaterialMarket')}
          >
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToToolMaterialMarketDescription')}
              isLoading={isLoadingApprovalStatusFTCToToolMaterialMarket}
              checked={hasApprovedFTCToToolMaterialMarket}
              onChange={setApprovalForAllFTCToToolMaterialMarket}
            />
            <ApprovalSettingsStatus
              title={t(
                'Component:ApprovalSettings:ApprovalToolMaterialToMaterialToolMarketDescription'
              )}
              isLoading={isLoadingApprovalStatusToolMaterialToToolMaterialMarket}
              checked={hasApprovedToolMaterialToToolMaterialMarket}
              onChange={setApprovalForAllToolMaterialToToolMaterialMarket}
            />
          </ApprovalSettingsSection>
        </GridItem>
        <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:GeneMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToGeneMarketDescription')}
              isLoading={isLoadingApprovalStatusFTCToGeneMarket}
              checked={hasApprovedFTCToGeneMarket}
              onChange={setApprovalForAllFTCToGeneMarket}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalGeneToGeneMarketDescription')}
              isLoading={isLoadingApprovalStatusGeneToGeneMarket}
              checked={hasApprovedGeneToGeneMarket}
              onChange={setApprovalForAllGeneToGeneMarket}
            />
          </ApprovalSettingsSection>
        </GridItem>
        <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:SlimeMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToSlimeMarketDescription')}
              isLoading={isLoadingApprovalStatusFTCToSlimeMarket}
              checked={hasApprovedFTCToSlimeMarket}
              onChange={setApprovalForAllFTCToSlimeMarket}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalSlimeToSlimeMarketDescription')}
              isLoading={isLoadingApprovalStatusSlimeToSlimeMarket}
              checked={hasApprovedSlimeToSlimeMarket}
              onChange={setApprovalForAllSlimeToSlimeMarket}
            />
          </ApprovalSettingsSection>
        </GridItem>
        <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:LandMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToLandMarketDescription')}
              isLoading={isLoadingApprovalStatusFTCToLandMarket}
              checked={hasApprovedFTCToLandMarket}
              onChange={setApprovalForAllFTCToLandMarket}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalLandToLandMarketDescription')}
              isLoading={isLoadingApprovalStatusLandToLandMarket}
              checked={hasApprovedLandToLandMarket}
              onChange={setApprovalForAllLandToLandMarket}
            />
          </ApprovalSettingsSection>
        </GridItem>
        <GridItem>
          <ApprovalSettingsSection sectionTitle={t('Component:ApprovalSettings:GachaBoxMarket')}>
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalFTCToGachaBoxMarketDescription')}
              isLoading={isLoadingApprovalStatusFTCToGachaBoxMarket}
              checked={hasApprovedFTCToGachaBoxMarket}
              onChange={setApprovalForAllFTCToGachaBoxMarket}
            />
            <ApprovalSettingsStatus
              title={t('Component:ApprovalSettings:ApprovalGachaBoxToGachaBoxMarketDescription')}
              isLoading={isLoadingApprovalStatusGachaBoxToGachaBoxMarket}
              checked={hasApprovedGachaBoxToGachaBoxMarket}
              onChange={setApprovalForAllGachaBoxToGachaBoxMarket}
            />
          </ApprovalSettingsSection>
        </GridItem>
      </Grid>
    </Center>
  );
};

export default ApprovalSettingsPanel;
