import { Text, useDisclosure, usePrevious } from '@chakra-ui/react';
import React, { useCallback, useEffect } from 'react';
import { PrimaryButton } from 'src/components';
import { useTranslation } from 'react-i18next';
import isFunction from 'lodash/isFunction';
import {
  parseDisplayPriceFromNumberWithDecimal,
  parseNumberWithDecimalFromDisplayPrice,
} from 'src/common/utils';
import useErrorModal from 'src/features/errorModal/hooks/useErrorModal';
import { INFT, TNFTType } from 'src/gql/types';
import { useIsMounted } from 'src/hooks';
import useUpdateNFTPriceOnFixedPriceSaleMarket from '../hooks/useUpdateNFTPriceOnFixedPriceSaleMarket';
import UpdatePriceModal from './UpdatePriceModal';

interface IUpdateSellPriceButtonProps {
  currentNFT?: INFT | null;
  saleId?: string;
  currentPrice?: string;
  currentAmount?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onUpdateSellPriceDone?: (txid: string) => void;
  nftType: TNFTType;
}

const UpdateSellPriceButton = ({
  currentNFT,
  saleId,
  currentPrice,
  currentAmount,
  isLoading,
  onUpdateSellPriceDone,
  nftType,
  isDisabled,
}: IUpdateSellPriceButtonProps) => {
  const { t } = useTranslation();
  const [openErrorModal] = useErrorModal();
  const isMounted = useIsMounted();

  const [
    isUpdateNFTPriceOnFixedPriceSaleMarket,
    onUpdateNFTPriceOnFixedPriceSaleMarket,
    updateNFTPriceOnFixedPriceSaleMarketErrorMessage,
  ] = useUpdateNFTPriceOnFixedPriceSaleMarket(nftType);

  const {
    isOpen: isOpenUpdatePriceModal,
    onOpen: onOpenUpdatePriceModal,
    onClose: onCloseUpdatePriceModal,
  } = useDisclosure({ defaultIsOpen: false });

  const onClickUpdateSalePriceButton = useCallback(() => {
    onOpenUpdatePriceModal();
  }, [onOpenUpdatePriceModal]);

  const isButtonLoading = !!isLoading || !!isUpdateNFTPriceOnFixedPriceSaleMarket;
  const isButtonDisabled = isButtonLoading || !!isDisabled;

  let buttonLoadingText;
  if (isUpdateNFTPriceOnFixedPriceSaleMarket) {
    buttonLoadingText = t('Component:UpdateSellPriceButton.UpdatingPrice');
  }

  const onUpdateSellPrice = useCallback(
    async (newPrice: string) => {
      if (saleId && isFunction(onUpdateNFTPriceOnFixedPriceSaleMarket)) {
        onUpdateNFTPriceOnFixedPriceSaleMarket(
          saleId,
          parseNumberWithDecimalFromDisplayPrice(newPrice),
          onUpdateSellPriceDone
        );
      }
    },
    [saleId, onUpdateNFTPriceOnFixedPriceSaleMarket, onUpdateSellPriceDone]
  );

  const prevIsUpdateMiningToolPriceOnFixedPriceSale = usePrevious<boolean>(
    isUpdateNFTPriceOnFixedPriceSaleMarket as boolean
  );
  useEffect(() => {
    if (!isMounted()) return;
    if (
      prevIsUpdateMiningToolPriceOnFixedPriceSale &&
      !isUpdateNFTPriceOnFixedPriceSaleMarket &&
      !updateNFTPriceOnFixedPriceSaleMarketErrorMessage
    ) {
      onCloseUpdatePriceModal();
    }
  }, [
    isMounted,
    prevIsUpdateMiningToolPriceOnFixedPriceSale,
    isUpdateNFTPriceOnFixedPriceSaleMarket,
    onCloseUpdatePriceModal,
    updateNFTPriceOnFixedPriceSaleMarketErrorMessage,
  ]);

  const prevUpdateMiningToolPriceOnFixedPriceSaleErrorMessage = usePrevious<string | undefined>(
    updateNFTPriceOnFixedPriceSaleMarketErrorMessage as string
  );
  useEffect(() => {
    if (!isMounted()) return;
    if (
      prevUpdateMiningToolPriceOnFixedPriceSaleErrorMessage !==
        updateNFTPriceOnFixedPriceSaleMarketErrorMessage &&
      updateNFTPriceOnFixedPriceSaleMarketErrorMessage
    ) {
      if (isFunction(openErrorModal))
        openErrorModal(updateNFTPriceOnFixedPriceSaleMarketErrorMessage);
    }
  }, [
    isMounted,
    prevUpdateMiningToolPriceOnFixedPriceSaleErrorMessage,
    updateNFTPriceOnFixedPriceSaleMarketErrorMessage,
    openErrorModal,
  ]);

  return (
    <>
      <PrimaryButton
        isLoading={isButtonLoading}
        loadingText={buttonLoadingText}
        borderRadius={'full'}
        onClick={onClickUpdateSalePriceButton}
        disabled={isButtonDisabled}
      >
        <Text>{t('Component:UpdateSellPriceButton.UpdatePrice')}</Text>
      </PrimaryButton>
      {isOpenUpdatePriceModal && (
        <UpdatePriceModal
          isLoading={isUpdateNFTPriceOnFixedPriceSaleMarket as boolean}
          currentNFT={currentNFT}
          defaultPrice={parseDisplayPriceFromNumberWithDecimal(currentPrice || '0').toString()}
          defaultAmount={currentAmount}
          isOpen={isOpenUpdatePriceModal}
          onClose={onCloseUpdatePriceModal}
          onConfirm={onUpdateSellPrice}
        />
      )}
    </>
  );
};

export default UpdateSellPriceButton;
