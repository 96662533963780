import { Box, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISlimeMetadata } from '../../../../gql/types';
import Part from './Part';

interface PartsStatPropsType {
  metadata: ISlimeMetadata;
}

const Parts = ({ metadata }: PartsStatPropsType) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <Box w="100%">
        <Part
          title={t('Component:SlimeDetails:Part:Head')}
          klass={metadata.headDetails.class}
          rarity={metadata.headDetails.rarity}
        >
          <Text>{`Critical Dmg: ${metadata.headAttrsDetails.critdmg.toFixed(0)}%`}</Text>
          <Text>{`Block: ${metadata.headAttrsDetails.block.toFixed(0)}%`}</Text>
        </Part>
        <Part
          title={t('Component:SlimeDetails:Part:SubBody')}
          klass={metadata.subDetails.class}
          rarity={metadata.subDetails.rarity}
        >
          <Text>{`Critical: ${metadata.subAttrsDetails.crit.toFixed(0)}%`}</Text>
          <Text>{`Evade: ${metadata.subAttrsDetails.evade.toFixed(0)}%`}</Text>
        </Part>
        <Part
          title={t('Component:SlimeDetails:Part:Body')}
          klass={metadata.bodyDetails.class}
          rarity={metadata.bodyDetails.rarity}
        >
          <Text>{`HP: ${metadata.bodyAttrsDetails.hp.toFixed(0)}%`}</Text>
          <Text>{`ATK: ${metadata.bodyAttrsDetails.atk.toFixed(0)}%`}</Text>
          <Text>{`DEF: ${metadata.bodyAttrsDetails.def.toFixed(0)}%`}</Text>
        </Part>
        <Part
          title={t('Component:SlimeDetails:Part:RArm')}
          klass={metadata.rightArmDetails.class}
          rarity={metadata.rightArmDetails.rarity}
        >
          <Text>{`HP: ${metadata.rightArmAttrsDetails.hp.toFixed(0)}%`}</Text>
          <Text>{`ATK: ${metadata.rightArmAttrsDetails.atk.toFixed(0)}%`}</Text>
          <Text>{`DEF: ${metadata.rightArmAttrsDetails.def.toFixed(0)}%`}</Text>
        </Part>
        <Part
          title={t('Component:SlimeDetails:Part:LArm')}
          klass={metadata.leftArmDetails.class}
          rarity={metadata.leftArmDetails.rarity}
        >
          <Text>{`HP: ${metadata.leftArmAttrsDetails.hp.toFixed(0)}%`}</Text>
          <Text>{`ATK: ${metadata.leftArmAttrsDetails.atk.toFixed(0)}%`}</Text>
          <Text>{`DEF: ${metadata.leftArmAttrsDetails.def.toFixed(0)}%`}</Text>
        </Part>
        <Part
          title={t('Component:SlimeDetails:Part:Face')}
          klass={metadata.faceDetails.class}
          rarity={metadata.faceDetails.rarity}
        >
          <Text>{`HP: ${metadata.faceAttrsDetails.hp.toFixed(0)}%`}</Text>
          <Text>{`Block: ${metadata.faceAttrsDetails.block.toFixed(0)}%`}</Text>
          <Text>{`Critical: ${metadata.faceAttrsDetails.critdmg.toFixed(0)}%`}</Text>
        </Part>
      </Box>
    </VStack>
  );
};

export default Parts;
