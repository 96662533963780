import { IFixedPriceSaleNFT, IMarketplaceSummary } from 'src/gql/types';

export const GET_MARKETPLACE_SUMMARIES_REQUEST = 'GET_MARKETPLACE_SUMMARIES_REQUEST';
export const GET_MARKETPLACE_SUMMARIES_SUCCESS = 'GET_MARKETPLACE_SUMMARIES_SUCCESS';
export const GET_MARKETPLACE_SUMMARIES_FAILURE = 'GET_MARKETPLACE_SUMMARIES_FAILURE';

export const GET_RECENTLY_LISTED_REQUEST = 'GET_RECENTLY_LISTED_REQUEST';
export const GET_RECENTLY_LISTED_SUCCESS = 'GET_RECENTLY_LISTED_SUCCESS';
export const GET_RECENTLY_LISTED_FAILURE = 'GET_RECENTLY_LISTED_FAILURE';

export const GET_RECENTLY_SOLD_REQUEST = 'GET_RECENTLY_SOLD_REQUEST';
export const GET_RECENTLY_SOLD_SUCCESS = 'GET_RECENTLY_SOLD_SUCCESS';
export const GET_RECENTLY_SOLD_FAILURE = 'GET_RECENTLY_SOLD_FAILURE';

export interface RecentlyListDataState {
  isGetting: boolean;
  gettingSuccess: boolean;
  gettingMessage: string;
  listOfFixedPriceSaleNFTs: Array<IFixedPriceSaleNFT>;
  totalFixedPriceSaleNFTs: number;
}

export const enum RecentlyListKey {
  RECENTLY_LISTED = 'recentlyListed',
  RECENTLY_SOLD = 'recentlySold',
}
export const enum RecentlyActionKey {
  RECENTLY_REQUEST = 'recentlyRequest',
  RECENTLY_SUCCESS = 'recentlySuccess',
  RECENTLY_FAILURE = 'recentlyFailure',
}
export const enum RecentlyListDataKey {
  MINING_TOOLS = 'miningTools',
  LANDS = 'lands',
  GENES = 'genes',
  SLIMES = 'slimes',
  GACHA_BOXES = 'gachaBoxes',
  TOOL_MATERIALS = 'toolMaterials',
}

export type TRecentlyListKey = 'recentlyListed' | 'recentlySold';
export type TRecentlyListDataKey =
  | 'miningTools'
  | 'lands'
  | 'genes'
  | 'slimes'
  | 'gachaBoxes'
  | 'toolMaterials';
export type TRecentlyActionKey = 'recentlyRequest' | 'recentlySuccess' | 'recentlyFailure';

export interface RecentlyListState {
  miningTools?: RecentlyListDataState;
  lands?: RecentlyListDataState;
  genes?: RecentlyListDataState;
  slimes?: RecentlyListDataState;
  gachaBoxes?: RecentlyListDataState;
  toolMaterials?: RecentlyListDataState;
}

export interface DashboardState {
  isGettingMarketplaceSummaries: boolean;
  gettingMarketplaceSummariesSuccess: boolean;
  gettingMarketplaceSummariesMessage: string;
  marketplaceSummaries: IMarketplaceSummary | null;
  recentlyListed: RecentlyListState;
  recentlySold: RecentlyListState;
}
