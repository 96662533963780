import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState, WalletAction } from 'src/redux';
import BalanceInDesktopView from './BalanceInDesktopView';
import BalanceInMobileView from './BalanceInMobileView';

interface IBalanceProps {
  isDesktopView: boolean;
}

const Balance = ({ isDesktopView }: IBalanceProps) => {
  const dispatch = useDispatch();

  const signedIn = useSelector((state: RootReducerState) => state.auth.signedIn);
  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );
  const balance = useSelector((state: RootReducerState) => state.wallet.FTCBalance);
  const isGettingFTCBalance = useSelector(
    (state: RootReducerState) => state.wallet.isGettingFTCBalance
  );

  useEffect(() => {
    if (signedIn && metaMaskWalletAddress) {
      dispatch(WalletAction.getFTCBalance());
    }
  }, [dispatch, signedIn, metaMaskWalletAddress]);

  if (!signedIn) {
    return null;
  }

  if (isDesktopView)
    return <BalanceInDesktopView balance={balance} isLoading={isGettingFTCBalance} />;

  return <BalanceInMobileView balance={balance} isLoading={isGettingFTCBalance} />;
};

export default Balance;
