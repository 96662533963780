import { Text, useColorModeValue, VStack, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ISlimeMetadata, SkillType } from '../../../../gql/types';
import { Colors } from '../../../../shared';
import Skill from './Skill';

interface SpecialSkillPropsType {
  metadata: ISlimeMetadata;
}

const SpecialSkill = ({ metadata }: SpecialSkillPropsType) => {
  const { t } = useTranslation();

  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  return (
    <VStack align="start">
      <Flex justifyContent="center" width="100%">
        <Text color={descriptionColor} pb={2}>
          {t('Component:SlimeDetails:Special')}
        </Text>
      </Flex>
      <VStack justify="space-around" w="100%">
        <Skill
          title=""
          skillType={SkillType.MYTHICAL}
          klass={metadata.bodyDetails.class}
          skillIndex={metadata.bodyDetails.skill}
        />
      </VStack>
    </VStack>
  );
};

export default SpecialSkill;
