import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './authentication';
import { marketplaceReducer } from './marketplace';
import { walletReducer } from './wallet';
import { accountReducer } from './account';
import { inventoryReducer } from './inventory';
import { appReducer } from './app';
import { dashboardReducer } from './dashboard';

const rootReducer = combineReducers({
  auth: authReducer,
  marketplace: marketplaceReducer,
  wallet: walletReducer,
  account: accountReducer,
  inventory: inventoryReducer,
  app: appReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
