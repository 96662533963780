export const CONNECTED_METAMASK_SUCCESS = 'CONNECTED_METAMASK_SUCCESS';
export const DISCONNECTED_METAMASK_SUCCESS = 'DISCONNECTED_METAMASK_SUCCESS';
export const GET_FTC_BALANCE_REQUEST = 'GET_FTC_BALANCE_REQUEST';
export const GET_FTC_BALANCE_SUCCESS = 'GET_FTC_BALANCE_SUCCESS';
export const GET_FTC_BALANCE_FAILURE = 'GET_FTC_BALANCE_FAILURE';

export interface WalletState {
  isMetaMaskConnected: boolean;
  metaMaskWalletAddress?: string;
  FTCBalance?: string;
  isGettingFTCBalance: boolean;
  getFTCBalanceErrorMessage?: string;
}
