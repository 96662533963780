import React, { useCallback, useEffect, useState } from 'react';
import { Image, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MetaMaskModalType } from 'src/globals/constants';
import { AppSizes, Colors, Resources } from 'src/shared';
import { isFunction } from 'lodash';
import OutlineButton from 'src/components/Button/OutlineButton';
import useDoConnectToMetaMask from '../hooks/useDoConnectToMetaMask';
import useCheckInstalledMetaMask from '../hooks/useCheckInstalledMetaMask';
import useDoSwitchMetaMaskToBSCNetwork from '../hooks/useDoSwitchMetaMaskToBSCNetwork';
import MetaMaskNotInstallModal from './MetaMaskNotInstallModal';
import MetaMaskNotConnectBSCModal from './MetaMaskNotConnectBSCModal';

const MetaMaskConnectButton = () => {
  const { t } = useTranslation();
  const color = useColorModeValue(Colors.light.primary, Colors.dark.primary);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = useState(MetaMaskModalType.METAMASK_NOT_INSTALL);

  const onMetaMaskNotInstalled = useCallback(() => {
    setModalType(MetaMaskModalType.METAMASK_NOT_INSTALL);
    onOpen();
  }, [onOpen, setModalType]);

  const onMetaMaskLocked = useCallback(() => {
    setModalType(MetaMaskModalType.METAMASK_NOT_UNLOCK);
    onOpen();
  }, [onOpen, setModalType]);

  const onMetaMaskHasNotAddedBSC = useCallback(() => {
    setModalType(MetaMaskModalType.METAMASK_NOT_CONNECT_BSC);
    onOpen();
  }, [onOpen, setModalType]);

  const isInstalledMetaMask = useCheckInstalledMetaMask();

  const [doConnectToMetaMask, isRequestingConnectMetaMask] = useDoConnectToMetaMask({
    onMetaMaskNotInstalled,
    onMetaMaskLocked,
    onMetaMaskHasNotAddedBSC,
  });

  const [
    isConnectedToBSCNetwork,
    isRequestingSwitchMetaMaskToBSCNetwork,
    doSwitchMetaMaskToBSCNetwork,
  ] = useDoSwitchMetaMaskToBSCNetwork();

  const onClickConnectMetaMask = useCallback(() => {
    if (!isInstalledMetaMask) {
      onMetaMaskNotInstalled();
      return;
    }
    if (!isConnectedToBSCNetwork) {
      onMetaMaskHasNotAddedBSC();
      return;
    }
    if (isFunction(doConnectToMetaMask)) doConnectToMetaMask();
  }, [
    doConnectToMetaMask,
    isInstalledMetaMask,
    isConnectedToBSCNetwork,
    onMetaMaskNotInstalled,
    onMetaMaskHasNotAddedBSC,
  ]);

  const onClickSwitchMetaMaskNetwork = useCallback(() => {
    if (isFunction(doSwitchMetaMaskToBSCNetwork)) doSwitchMetaMaskToBSCNetwork();
  }, [doSwitchMetaMaskToBSCNetwork]);

  useEffect(() => {
    if (!isRequestingSwitchMetaMaskToBSCNetwork && isConnectedToBSCNetwork) {
      onClose();
    }
  }, [isConnectedToBSCNetwork, isRequestingSwitchMetaMaskToBSCNetwork, onClose]);

  return (
    <>
      <OutlineButton
        color={color}
        onClick={onClickConnectMetaMask}
        isDisabled={isRequestingConnectMetaMask as boolean}
      >
        <Image boxSize={AppSizes.MenuIcon} src={Resources.svgs.metamask} marginRight={3} />
        <Text>{t('Component:MetaMaskConnectButton.MainText')}</Text>
      </OutlineButton>
      <MetaMaskNotInstallModal
        isOpen={isOpen && modalType === MetaMaskModalType.METAMASK_NOT_INSTALL}
        onClose={onClose}
      />
      <MetaMaskNotConnectBSCModal
        isOpen={isOpen && modalType === MetaMaskModalType.METAMASK_NOT_CONNECT_BSC}
        isLoading={isRequestingSwitchMetaMaskToBSCNetwork as boolean}
        onClose={onClose}
        onSwitchNetwork={onClickSwitchMetaMaskNetwork}
      />
    </>
  );
};

export default MetaMaskConnectButton;
