import React from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';
import { coreUtils } from 'src/common';
import { Colors } from 'src/shared';
import Label from './Label';

const GeneClassLabel = (props: { geneClass: number; [key: string]: any }) => {
  const { t } = useTranslation();

  const { geneClass, ...remainingProps } = props;
  const attributes = coreUtils.getAttributesByGeneClass(geneClass);
  const bg = useColorModeValue(attributes?.light, attributes?.dark);
  const color = useColorModeValue(Colors.light.whiteText, Colors.dark.whiteText);

  if (isNil(attributes)) return null;
  return (
    <Label
      color={color}
      bg={bg}
      label={t(attributes?.label as string)}
      minWidth="50px"
      rounded={4}
      {...remainingProps}
    />
  );
};

export default GeneClassLabel;
