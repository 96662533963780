import { Dispatch } from 'redux';
import {
  IFixedPriceSaleNFTVariable,
  IListFixedPriceSaleNFTResult,
  IMarketplaceSummary,
  OrderByDirection,
  OrderByKey,
  TNFTType,
} from 'src/gql/types';
import { FixedPriceSaleStatus } from 'src/globals/constants';
import { DefaultFixedPriceSaleNFTVariable } from 'src/gql/constants';
import { getOrderByVariableByType } from 'src/gql/common';
import * as GQLApi from 'src/gql';
import * as Types from './types';

const getMarketplaceSummariesRequest = () => {
  return {
    type: Types.GET_MARKETPLACE_SUMMARIES_REQUEST,
  };
};

const getMarketplaceSummariesSuccess = (payload: IMarketplaceSummary) => {
  return {
    type: Types.GET_MARKETPLACE_SUMMARIES_SUCCESS,
    payload,
  };
};

const getMarketplaceSummariesFailure = (errorMessage: string) => {
  return {
    type: Types.GET_MARKETPLACE_SUMMARIES_FAILURE,
    payload: {
      errorMessage,
    },
  };
};

export const getMarketplaceSummaries = () => {
  return (dispatch: Dispatch) => {
    dispatch(getMarketplaceSummariesRequest());
    return GQLApi.getMarketplaceSummaries()
      .then((result) => {
        return dispatch(getMarketplaceSummariesSuccess(result));
      })
      .catch((e) => dispatch(getMarketplaceSummariesFailure(e)));
  };
};

const getRecentlyListedByNFTRequest = (nftType: TNFTType) => {
  return {
    type: Types.GET_RECENTLY_LISTED_REQUEST,
    payload: {
      nftType,
    },
  };
};

const getRecentlyListedByNFTSuccess = (nftType: TNFTType, data: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_RECENTLY_LISTED_SUCCESS,
    payload: {
      ...data,
      nftType,
    },
  };
};

const getRecentlyListedByNFTFailure = (nftType: TNFTType, errorMessage: string) => {
  return {
    type: Types.GET_RECENTLY_LISTED_FAILURE,
    payload: {
      nftType,
      errorMessage,
    },
  };
};

export const getRecentlyListedByNFT = (nftType: TNFTType) => {
  const orderBy = getOrderByVariableByType(nftType, {
    [OrderByKey.UPDATED_AT]: OrderByDirection.DESC,
  });
  const variable: IFixedPriceSaleNFTVariable = {
    ...DefaultFixedPriceSaleNFTVariable,
    orderBy,
  };
  return (dispatch: Dispatch) => {
    dispatch(getRecentlyListedByNFTRequest(nftType));
    return GQLApi.getFixedPriceSaleByStatus(nftType, FixedPriceSaleStatus.AVAILABLE, variable)
      .then((result) => {
        return dispatch(getRecentlyListedByNFTSuccess(nftType, result));
      })
      .catch((e) => {
        console.log('err', e);
        dispatch(getRecentlyListedByNFTFailure(nftType, e));
      });
  };
};

const getRecentlySoldByNFTRequest = (nftType: TNFTType) => {
  return {
    type: Types.GET_RECENTLY_SOLD_REQUEST,
    payload: {
      nftType,
    },
  };
};

const getRecentlySoldByNFTSuccess = (nftType: TNFTType, data: IListFixedPriceSaleNFTResult) => {
  return {
    type: Types.GET_RECENTLY_SOLD_SUCCESS,
    payload: {
      ...data,
      nftType,
    },
  };
};

const getRecentlySoldByNFTFailure = (nftType: TNFTType, errorMessage: string) => {
  return {
    type: Types.GET_RECENTLY_SOLD_FAILURE,
    payload: {
      nftType,
      errorMessage,
    },
  };
};

export const getRecentlySoldByNFT = (nftType: TNFTType) => {
  const orderBy = getOrderByVariableByType(nftType, {
    [OrderByKey.UPDATED_AT]: OrderByDirection.DESC,
  });
  const variable: IFixedPriceSaleNFTVariable = {
    ...DefaultFixedPriceSaleNFTVariable,
    orderBy,
  };
  return (dispatch: Dispatch) => {
    dispatch(getRecentlySoldByNFTRequest(nftType));
    return GQLApi.getFixedPriceSaleByStatus(nftType, FixedPriceSaleStatus.BOUGHT, variable)
      .then((result) => {
        return dispatch(getRecentlySoldByNFTSuccess(nftType, result));
      })
      .catch((e) => {
        console.log('err', e);
        dispatch(getRecentlySoldByNFTFailure(nftType, e));
      });
  };
};
