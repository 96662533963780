import get from 'lodash/get';
import apolloClient from '../apolloClient';
import { getFilterVariableByType } from '../common';
import {
  INFT,
  TNFTType,
  INFTVariable,
  INFTsResult,
  IMyNFTDetailVariable,
  IGetNFTsVariable,
  IFilter,
} from '../types';
import {
  GET_MY_NFT_DETAIL_KEY,
  GET_MY_NFTS_KEY,
  getMyNFTDetailQuery,
  getMyNFTsQuery,
} from './queries';

export const getMyNFTsByNFTType = async (
  nftType: TNFTType,
  variables: INFTVariable
): Promise<INFTsResult> => {
  try {
    const { filter: filterByNFTType, ...remainingVariables } = variables;
    const filter = getFilterVariableByType(nftType, filterByNFTType as IFilter);
    const newVariables: IGetNFTsVariable = { ...remainingVariables, filter, type: nftType };
    const response = await apolloClient.query<INFTsResult>({
      query: getMyNFTsQuery(nftType),
      variables: newVariables,
    });
    const data = get(response, `data.${GET_MY_NFTS_KEY}`, {
      total: 0,
      records: [],
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error('getMyNFTsByNFTType, error', error);
    return Promise.reject(error);
  }
};

export const getMyNFTDetailByNFTType = async (
  nftType: TNFTType,
  variables: IMyNFTDetailVariable
): Promise<INFT> => {
  try {
    const response = await apolloClient.query<INFT>({
      query: getMyNFTDetailQuery(nftType),
      variables,
    });
    const data = get(response, `data.${GET_MY_NFT_DETAIL_KEY}`);
    return Promise.resolve(data);
  } catch (error) {
    console.error('getMyNFTDetailByNFTType, error', error);
    return Promise.reject(error);
  }
};
