import { IAction } from 'src/redux/types';
import * as Types from './types';
import { RecentlyActionKey, RecentlyListDataKey, RecentlyListKey } from './types';
import { initialRecentlyState } from './constants';
import { updateRecentlyList } from './helper';

const initialState: Types.DashboardState = {
  isGettingMarketplaceSummaries: false,
  gettingMarketplaceSummariesSuccess: false,
  gettingMarketplaceSummariesMessage: '',
  marketplaceSummaries: null,
  recentlyListed: {
    [RecentlyListDataKey.MINING_TOOLS]: initialRecentlyState,
    [RecentlyListDataKey.LANDS]: initialRecentlyState,
    [RecentlyListDataKey.GENES]: initialRecentlyState,
    [RecentlyListDataKey.SLIMES]: initialRecentlyState,
    [RecentlyListDataKey.GACHA_BOXES]: initialRecentlyState,
    [RecentlyListDataKey.TOOL_MATERIALS]: initialRecentlyState,
  },
  recentlySold: {
    [RecentlyListDataKey.MINING_TOOLS]: initialRecentlyState,
    [RecentlyListDataKey.LANDS]: initialRecentlyState,
    [RecentlyListDataKey.GENES]: initialRecentlyState,
    [RecentlyListDataKey.SLIMES]: initialRecentlyState,
    [RecentlyListDataKey.GACHA_BOXES]: initialRecentlyState,
    [RecentlyListDataKey.TOOL_MATERIALS]: initialRecentlyState,
  },
};

const dashboardReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case Types.GET_MARKETPLACE_SUMMARIES_REQUEST:
      return {
        ...state,
        isGettingMarketplaceSummaries: true,
        gettingMarketplaceSummariesSuccess: false,
        gettingMarketplaceSummariesMessage: '',
        marketplaceSummaries: null,
      };
    case Types.GET_MARKETPLACE_SUMMARIES_SUCCESS:
      return {
        ...state,
        isGettingMarketplaceSummaries: false,
        gettingMarketplaceSummariesSuccess: true,
        gettingMarketplaceSummariesMessage: '',
        marketplaceSummaries: action.payload,
      };
    case Types.GET_MARKETPLACE_SUMMARIES_FAILURE:
      return {
        ...state,
        isGettingMarketplaceSummaries: false,
        gettingMarketplaceSummariesSuccess: false,
        gettingMarketplaceSummariesMessage: action.payload.errorMessage,
        marketplaceSummaries: null,
      };
    case Types.GET_RECENTLY_LISTED_REQUEST:
      return updateRecentlyList(
        state,
        RecentlyListKey.RECENTLY_LISTED,
        RecentlyActionKey.RECENTLY_REQUEST,
        action.payload
      );

    case Types.GET_RECENTLY_LISTED_SUCCESS:
      return updateRecentlyList(
        state,
        RecentlyListKey.RECENTLY_LISTED,
        RecentlyActionKey.RECENTLY_SUCCESS,
        action.payload
      );

    case Types.GET_RECENTLY_LISTED_FAILURE:
      return updateRecentlyList(
        state,
        RecentlyListKey.RECENTLY_LISTED,
        RecentlyActionKey.RECENTLY_FAILURE,
        action.payload
      );
    case Types.GET_RECENTLY_SOLD_REQUEST:
      return updateRecentlyList(
        state,
        RecentlyListKey.RECENTLY_SOLD,
        RecentlyActionKey.RECENTLY_REQUEST,
        action.payload
      );

    case Types.GET_RECENTLY_SOLD_SUCCESS:
      return updateRecentlyList(
        state,
        RecentlyListKey.RECENTLY_SOLD,
        RecentlyActionKey.RECENTLY_SUCCESS,
        action.payload
      );

    case Types.GET_RECENTLY_SOLD_FAILURE:
      return updateRecentlyList(
        state,
        RecentlyListKey.RECENTLY_SOLD,
        RecentlyActionKey.RECENTLY_FAILURE,
        action.payload
      );
    default:
      return state;
  }
};

export default dashboardReducer;
