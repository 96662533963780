import { Text, useColorModeValue, VStack, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ISlimeMetadata, SkillType } from '../../../../gql/types';
import { Colors } from '../../../../shared';
import Skill from './Skill';

interface PassiveSkillPropsType {
  metadata: ISlimeMetadata;
}

const PassiveSkill = ({ metadata }: PassiveSkillPropsType) => {
  const { t } = useTranslation();

  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);

  return (
    <VStack align="start">
      <Flex justifyContent="center" width="100%">
        <Text color={descriptionColor} pb={2}>
          {t('Component:SlimeDetails:Passive')}
        </Text>
      </Flex>
      <VStack justify="space-around" w="100%">
        {metadata.headDetails.skill % 2 !== 0 && (
          <Skill
            title={t('Component:SlimeDetails:Part:Head')}
            skillType={SkillType.PASSIVE}
            klass={metadata.headDetails.class}
            skillIndex={metadata.headDetails.skill}
            rarity={metadata.headDetails.rarity}
          />
        )}
        <Skill
          title={t('Component:SlimeDetails:Part:Body')}
          skillType={SkillType.PASSIVE}
          klass={metadata.bodyDetails.class}
          skillIndex={metadata.bodyDetails.skill}
          rarity={metadata.bodyDetails.rarity}
        />
        <Skill
          title={t('Component:SlimeDetails:Part:SubBody')}
          skillType={SkillType.PASSIVE}
          klass={metadata.subDetails.class}
          skillIndex={metadata.subDetails.skill}
          rarity={metadata.subDetails.rarity}
        />
      </VStack>
    </VStack>
  );
};

export default PassiveSkill;
