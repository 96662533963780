import { Box, HStack, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoItem from '../InfoItem';
import { GeneClassLabel, RarityLabel } from '../../../../components';
import { ISlimeMetadata } from '../../../../gql/types';
import PowerStat from './PowerStat';
import BattleStat from './BattleStat';

interface StatPropsType {
  metadata: ISlimeMetadata;
}

const Stat = ({ metadata }: StatPropsType) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="start">
      <HStack alignItems={'inherit'}>
        <InfoItem title={t('Term:GeneClass')}>
          <GeneClassLabel geneClass={metadata.rightArmDetails.class} />
        </InfoItem>
        <InfoItem title={t('Term:Rarity')}>
          <RarityLabel rarity={metadata.faceDetails.rarity} />
        </InfoItem>
      </HStack>
      <Box m="10" w="100%">
        <PowerStat metadata={metadata} />
      </Box>
      <Box m="10" w="100%">
        <BattleStat metadata={metadata} />
      </Box>
    </VStack>
  );
};

export default Stat;
