import React from 'react';
import { Box, useColorModeValue, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Colors } from '../../../shared';
import useAuthenticatedWithAccountServiceByWalletAddress from '../hooks/useAuthenticatedWithAccountServiceByWalletAddress';
import useAccountInformation from '../hooks/useAccountInformation';
import { RootReducerState } from '../../../redux';
import UsernameSetting from './UsernameSetting';
import EmailSetting from './EmailSetting';
import PasswordSetting from './PasswordSetting';

interface ApprovalSettingsPanelProps {}

const AccountSettingsPanel = (props: ApprovalSettingsPanelProps) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );
  const { isLoadingAccountInfo, isAuthenticated } = useAccountInformation();
  const { isLoggingWithWallet, loginWithWallet } =
    useAuthenticatedWithAccountServiceByWalletAddress(metaMaskWalletAddress!);

  return (
    <Box maxW={400} mt={10} ml={10}>
      {!isAuthenticated && (
        <Box mb={5}>
          <Button
            disabled={isLoadingAccountInfo || isLoggingWithWallet}
            isLoading={isLoggingWithWallet}
            onClick={loginWithWallet}
            loadingText={t('Component:AccountSettings.LoadAccountInformationBtn')}
          >
            {t('Component:AccountSettings.LoadAccountInformationBtn')}
          </Button>
        </Box>
      )}
      <Box mb={5}>
        <UsernameSetting />
      </Box>
      <Box mb={5}>
        <EmailSetting />
      </Box>
      <Box>
        <PasswordSetting />
      </Box>
    </Box>
  );
};

export default AccountSettingsPanel;
