import get from 'lodash/get';
import { FixedPriceSaleStatus } from 'src/globals/constants';
import apolloClient from '../apolloClient';
import {
  IFixedPriceSaleNFTVariable,
  IListFixedPriceSaleNFTResult,
  IFixedPriceSaleNFT,
  TNFTType,
  IFixedPriceSaleNFTDetailVariable,
  TFixedPriceSaleStatus,
  OrderByKey,
  OrderByDirection,
} from '../types';
import { DefaultFixedPriceSaleNFTVariable } from '../constants';
import { getOrderByVariableByType } from '../common';
import {
  GET_FIXED_PRICE_SALE_DETAIL_KEY,
  GET_FIXED_PRICE_SALE_KEY,
  getFixedPriceSaleDetailQuery,
  getFixedPriceSalesQuery,
} from './queries';

export const getFixedPriceSalesByNFTType = async (
  nftType: TNFTType,
  variables: IFixedPriceSaleNFTVariable
): Promise<IListFixedPriceSaleNFTResult> => {
  try {
    const newVariables = { ...variables, type: nftType };
    const response = await apolloClient.query<IListFixedPriceSaleNFTResult>({
      query: getFixedPriceSalesQuery(nftType),
      variables: newVariables,
    });
    const data = get(response, `data.${GET_FIXED_PRICE_SALE_KEY}`, {
      total: 0,
      records: [],
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error('getFixedPriceSalesByNFTType, error', error);
    return Promise.reject(error);
  }
};

export const getFixedPriceSaleDetailByNFTType = async (
  nftType: TNFTType,
  saleId: string
): Promise<IFixedPriceSaleNFT> => {
  try {
    const variables: IFixedPriceSaleNFTDetailVariable = { saleId, type: nftType };
    const response = await apolloClient.query<IFixedPriceSaleNFT>({
      query: getFixedPriceSaleDetailQuery(nftType),
      variables,
    });
    const data = get(response, `data.${GET_FIXED_PRICE_SALE_DETAIL_KEY}`);
    return Promise.resolve(data);
  } catch (error) {
    console.error('getFixedPriceSaleDetailByNFTType, error', error);
    return Promise.reject(error);
  }
};

export const getFixedPriceSaleByStatus = async (
  nftType: TNFTType,
  status: TFixedPriceSaleStatus,
  variables = DefaultFixedPriceSaleNFTVariable
): Promise<IListFixedPriceSaleNFTResult> => {
  try {
    const newVariables = {
      ...variables,
      status,
      type: nftType,
    };
    const response = await apolloClient.query<IListFixedPriceSaleNFTResult>({
      query: getFixedPriceSalesQuery(nftType),
      variables: newVariables,
    });
    const data = get(response, `data.${GET_FIXED_PRICE_SALE_KEY}`, {
      total: 0,
      records: [],
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error('getFixedPriceSaleByStatus, error', error);
    return Promise.reject(error);
  }
};

export const getSaleHistories = async (
  nftType: TNFTType,
  tokenId: number,
  variables = {
    pagination: {
      skip: 0,
      limit: 5,
    },
  }
): Promise<IListFixedPriceSaleNFTResult> => {
  try {
    const orderBy = getOrderByVariableByType(nftType, {
      [OrderByKey.UPDATED_AT]: OrderByDirection.DESC,
    });
    const newVariables = {
      ...variables,
      status: FixedPriceSaleStatus.BOUGHT,
      type: nftType,
      tokenId,
      orderBy,
    };
    const response = await apolloClient.query<IListFixedPriceSaleNFTResult>({
      query: getFixedPriceSalesQuery(nftType),
      variables: newVariables,
    });
    const data = get(response, `data.${GET_FIXED_PRICE_SALE_KEY}`, {
      total: 0,
      records: [],
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error('getSaleHistories, error', error);
    return Promise.reject(error);
  }
};
