import * as Types from './types';

const initialState: Types.AccountState = {
  userActivities: [],
  userActivitiesTotal: 0,
};

const accountReducer = (state = initialState, action: any): Types.AccountState => {
  switch (action.type) {
    case Types.UPDATE_USER_ACTIVITIES_STATE: {
      return {
        ...state,
        userActivities: action?.payload?.records || [],
        userActivitiesTotal: action?.payload?.total || 0,
      };
    }

    default:
      return state;
  }
};

export default accountReducer;
