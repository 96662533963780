import React, { useCallback } from 'react';
import {
  Button,
  ButtonProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
  InputRightElement,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getConfig } from 'src/globals/env-config';
import { AppSizes, Colors } from 'src/shared';
import { INFT, TNFTType } from 'src/gql/types';
import { NFTFormat } from 'src/globals/constants';
import { coreUtils } from 'src/common';
import * as nftDetailCommon from '../common';
import useNftName from '../../../hooks/useNftName';

interface IPlaceOrderModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: (price: string, amount: number) => void;
  isApproved: boolean;
  isApproving: boolean;
  onApprove: () => void;
  currentNFT: INFT | null;
  defaultPrice?: string;
}

const PlaceOrderModal = ({
  isOpen,
  onClose,
  currentNFT,
  onConfirm,
  isApproved,
  isApproving,
  onApprove,
  isLoading,
  defaultPrice = '',
}: IPlaceOrderModalProps) => {
  const { t } = useTranslation();

  const nftName = coreUtils.getNFTTokenNameByNftType(currentNFT?.type as TNFTType);
  const descriptionText = t('Component:PlaceOrderModal.Description', {
    token: t(nftName),
  });
  const primaryBg = useColorModeValue(Colors.light.primary, Colors.dark.primary);
  const descriptionColor = useColorModeValue(Colors.light.description, Colors.dark.description);
  const borderColor = useColorModeValue(Colors.light.text, Colors.dark.text);

  let approveButtonStyle: ButtonProps = {
    ...styles.button,
    isDisabled: isApproved,
  };
  if (!isApproved) {
    approveButtonStyle = { ...approveButtonStyle, bg: primaryBg };
  }

  let sellNowButtonStyle: ButtonProps = {
    ...styles.button,
    isDisabled: !isApproved,
    mr: 3,
    type: 'submit' as 'submit',
  };
  if (isApproved) {
    sellNowButtonStyle = { ...sellNowButtonStyle, bg: primaryBg };
  }
  const maxAvailableAmountForSale = nftDetailCommon.getMaxAvailableAmountForSale(currentNFT);
  const showMaxSelector = maxAvailableAmountForSale > 1;
  const onSubmit = useCallback(
    ({ priceInput, amountInput }: { priceInput: string; amountInput: string }) => {
      const amountForSell = currentNFT?.format === NFTFormat.ERC721 ? '1' : amountInput;
      if (priceInput && amountForSell) {
        onConfirm(priceInput, parseInt(amountInput));
      }
    },
    [onConfirm, currentNFT]
  );

  const setMaxAvailableForSell = () => {
    setValue('amountInput', maxAvailableAmountForSale.toString());
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<{ priceInput: string; amountInput: string }>();

  const modalBackgroundColor = useColorModeValue(
    Colors.light.modalBackground,
    Colors.dark.modalBackground
  );
  const amountDisabled = currentNFT?.format === NFTFormat.ERC721 ? true : !isApproved || isLoading;
  const defaultAmount = currentNFT?.format === NFTFormat.ERC721 ? '1' : '';
  const isERC721 = currentNFT?.format === NFTFormat.ERC721;

  const nftItemName = useNftName({
    item: currentNFT || undefined,
    nftType: currentNFT?.type as TNFTType | undefined,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalContent backgroundColor={modalBackgroundColor}>
          <ModalHeader textAlign={'center'}>{nftItemName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} pb={6} spacing={4}>
            {!isApproved && (
              <Text color={descriptionColor} textAlign={'center'}>
                {descriptionText}
              </Text>
            )}
            <HStack spacing={4} alignItems="flex-start">
              <FormControl isInvalid={!!errors.priceInput} flex={isERC721 ? 1 : 0.6}>
                <FormLabel htmlFor="priceInput">
                  {t('Component:PlaceOrderModal.Input.Price')}:
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon children="FTC" borderColor={borderColor} />
                  <Input
                    borderColor={borderColor}
                    defaultValue={defaultPrice}
                    disabled={!isApproved || isLoading}
                    placeholder={t('Component:PlaceOrderModal.Holder.Price')}
                    type="number"
                    {...register('priceInput', {
                      required: {
                        value: true,
                        message: t('Component:PlaceOrderModal.Error.RequiredPrice'),
                      },
                      min: {
                        value: getConfig('REACT_APP_MIN_PRICE_SUPPORTED'),
                        message: t('Component:PlaceOrderModal.Error.MinPrice', {
                          minPrice: getConfig('REACT_APP_MIN_PRICE_SUPPORTED'),
                        }),
                      },
                      max: {
                        value: getConfig('REACT_APP_MAX_PRICE_SUPPORTED'),
                        message: t('Component:PlaceOrderModal.Error.MaxPrice', {
                          maxPrice: getConfig('REACT_APP_MAX_PRICE_SUPPORTED'),
                        }),
                      },
                    })}
                  />
                </InputGroup>
                {errors.priceInput && (
                  <FormErrorMessage>{errors.priceInput?.message}</FormErrorMessage>
                )}
              </FormControl>
              {!isERC721 && (
                <FormControl isInvalid={!!errors.amountInput} flex={0.4}>
                  <FormLabel htmlFor="amountInput">
                    {t('Component:PlaceOrderModal.Input.Amount')}:
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      borderColor={borderColor}
                      defaultValue={defaultAmount}
                      disabled={amountDisabled}
                      placeholder={t('Component:PlaceOrderModal.Holder.Amount')}
                      type="number"
                      {...register('amountInput', {
                        disabled: amountDisabled,
                        required: {
                          value: true,
                          message: t('Component:PlaceOrderModal.Error.RequiredAmount'),
                        },
                        min: {
                          value: 1,
                          message: t('Component:PlaceOrderModal.Error.MinAmount', {
                            min: 1,
                          }),
                        },
                        max: {
                          value: maxAvailableAmountForSale,
                          message: t('Component:PlaceOrderModal.Error.MaxAmount', {
                            max: maxAvailableAmountForSale,
                          }),
                        },
                      })}
                    />
                    {showMaxSelector && (
                      <InputRightElement pr={1}>
                        <Button h="1.75rem" size="xs" onClick={setMaxAvailableForSell}>
                          {t('Component:PlaceOrderModal.Button.Max')}
                        </Button>
                      </InputRightElement>
                    )}
                  </InputGroup>
                  {errors.amountInput && (
                    <FormErrorMessage>{errors.amountInput.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            </HStack>{' '}
            {!isERC721 && (
              <Text>
                {t('Component:PlaceOrderModal.AvailableForSale')}: {maxAvailableAmountForSale}
              </Text>
            )}
          </ModalBody>

          <ModalFooter mx={'auto'}>
            <Button {...sellNowButtonStyle} isLoading={isLoading}>
              {t('Common:SellNow')}
            </Button>
            {!isApproved && (
              <Button {...approveButtonStyle} onClick={onApprove} isLoading={isApproving}>
                {t('Common:Approve')}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default PlaceOrderModal;

const styles = {
  button: {
    borderRadius: 'full' as 'full',
    minWidth: AppSizes.ButtonMinWidth,
  },
};
