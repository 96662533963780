import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'src/redux';
import WalletAddressInDesktopView from './WalletAddressInDesktopView';
import WalletAddressInMobileView from './WalletAddressInMobileView';

interface IWalletAddressProps {
  isDesktopView: boolean;
}

const WalletAddress = ({ isDesktopView }: IWalletAddressProps) => {
  const metaMaskWalletAddress = useSelector(
    (state: RootReducerState) => state.wallet.metaMaskWalletAddress
  );

  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const onClickCopyAddress = useCallback(() => {
    if (metaMaskWalletAddress) {
      navigator.clipboard.writeText(metaMaskWalletAddress);
      setHasCopied(true);
    }
  }, [metaMaskWalletAddress, setHasCopied]);

  useEffect(() => {
    if (hasCopied) {
      setTimeout(() => {
        setHasCopied(false);
      }, 1000);
    }
  }, [hasCopied]);

  if (!metaMaskWalletAddress) {
    return null;
  }

  if (isDesktopView)
    return (
      <WalletAddressInDesktopView
        walletAddress={metaMaskWalletAddress}
        onClickCopyAddress={onClickCopyAddress}
        hasCopied={hasCopied}
      />
    );

  return (
    <WalletAddressInMobileView
      walletAddress={metaMaskWalletAddress}
      onClickCopyAddress={onClickCopyAddress}
      hasCopied={hasCopied}
    />
  );
};

export default WalletAddress;
