import {
  ISlimeBodyAttrsDetails,
  ISlimeBodyDetail,
  ISlimeFaceAttrsDetails,
  ISlimeFaceDetail,
  ISlimeHeadAttrsDetails,
  ISlimeHeadDetail,
  ISlimeLeftArmAttrsDetails,
  ISlimeLeftArmDetail,
  ISlimeMetadata,
  ISlimeRightArmAttrsDetails,
  ISlimeRightArmDetail,
  ISlimeSubAttrsDetails,
  ISlimeSubDetail,
  SlimePart,
} from 'src/gql/types';

const config = {
  statConversion: {
    hp: 255,
    atk: 23,
    def: 9,
    crit: 0.3,
    critdmg: 1,
    block: 0.3,
    evade: 0.2,
  },
  attributePoints: [30, 50, 80, 120, 150],
  comboBonusPoints: [0, 0, 20, 40, 60, 60],
};

export const ATK_SPEED = 75;

class Stat {
  points: number;
  value: number;

  constructor(points: number, value: number) {
    this.points = points;
    this.value = value;
  }
}

export class FullStatGroup {
  hp: Stat;
  atk: Stat;
  def: Stat;
  crit: Stat;
  critdmg: Stat;
  evade: Stat;
  block: Stat;

  constructor(
    hp?: Stat,
    atk?: Stat,
    def?: Stat,
    crit?: Stat,
    critdmg?: Stat,
    evade?: Stat,
    block?: Stat
  ) {
    this.hp = hp || new Stat(0, 0);
    this.atk = atk || new Stat(0, 0);
    this.def = def || new Stat(0, 0);
    this.crit = crit || new Stat(0, 0);
    this.critdmg = critdmg || new Stat(0, 0);
    this.evade = evade || new Stat(0, 0);
    this.block = block || new Stat(0, 0);
  }
}

export class SlimeInfoMetadata implements ISlimeMetadata {
  body: string;
  bodyAttrs: string;
  bodyAttrsDetails: ISlimeBodyAttrsDetails;
  bodyComboPoints: string;
  bodyDetails: ISlimeBodyDetail;
  bodyMods: string;
  face: string;
  faceAttrs: string;
  faceAttrsDetails: ISlimeFaceAttrsDetails;
  faceDetails: ISlimeFaceDetail;
  head: string;
  headAttrs: string;
  headAttrsDetails: ISlimeHeadAttrsDetails;
  headComboPoints: string;
  headDetails: ISlimeHeadDetail;
  headMods: string;
  leftArm: string;
  leftArmAttrs: string;
  leftArmAttrsDetails: ISlimeLeftArmAttrsDetails;
  leftArmComboPoints: string;
  leftArmDetails: ISlimeLeftArmDetail;
  leftArmMods: string;
  mythicalSkill: string;
  reserved: string;
  rightArm: string;
  rightArmAttrs: string;
  rightArmAttrsDetails: ISlimeRightArmAttrsDetails;
  rightArmComboPoints: string;
  rightArmDetails: ISlimeRightArmDetail;
  rightArmMods: string;
  sub: string;
  subAttrs: string;
  subAttrsDetails: ISlimeSubAttrsDetails;
  subComboPoints: string;
  subDetails: ISlimeSubDetail;
  subMods: string;

  private _fullStatGroup: FullStatGroup | null = null;

  constructor(metadata: ISlimeMetadata) {
    this.body = metadata.body;
    this.bodyAttrs = metadata.bodyAttrs;
    this.bodyAttrsDetails = metadata.bodyAttrsDetails;
    this.bodyComboPoints = metadata.bodyComboPoints;
    this.bodyDetails = metadata.bodyDetails;
    this.bodyMods = metadata.bodyMods;
    this.face = metadata.face;
    this.faceAttrs = metadata.faceAttrs;
    this.faceAttrsDetails = metadata.faceAttrsDetails;
    this.faceDetails = metadata.faceDetails;
    this.head = metadata.head;
    this.headAttrs = metadata.headAttrs;
    this.headAttrsDetails = metadata.headAttrsDetails;
    this.headComboPoints = metadata.headComboPoints;
    this.headDetails = metadata.headDetails;
    this.headMods = metadata.headMods;
    this.leftArm = metadata.leftArm;
    this.leftArmAttrs = metadata.leftArmAttrs;
    this.leftArmAttrsDetails = metadata.leftArmAttrsDetails;
    this.leftArmComboPoints = metadata.leftArmComboPoints;
    this.leftArmDetails = metadata.leftArmDetails;
    this.leftArmMods = metadata.leftArmMods;
    this.mythicalSkill = metadata.mythicalSkill;
    this.reserved = metadata.reserved;
    this.rightArm = metadata.rightArm;
    this.rightArmAttrs = metadata.rightArmAttrs;
    this.rightArmAttrsDetails = metadata.rightArmAttrsDetails;
    this.rightArmComboPoints = metadata.rightArmComboPoints;
    this.rightArmDetails = metadata.rightArmDetails;
    this.rightArmMods = metadata.rightArmMods;
    this.sub = metadata.sub;
    this.subAttrs = metadata.subAttrs;
    this.subAttrsDetails = metadata.subAttrsDetails;
    this.subComboPoints = metadata.subComboPoints;
    this.subDetails = metadata.subDetails;
    this.subMods = metadata.subMods;
  }

  get fullStatGroup(): FullStatGroup {
    if (this._fullStatGroup) {
      return this._fullStatGroup;
    }
    const self = this;
    const comboPoints = {
      [SlimePart.Body]: this.calcComboPoints(SlimePart.Body),
      [SlimePart.Sub]: this.calcComboPoints(SlimePart.Sub),
      [SlimePart.Head]: this.calcComboPoints(SlimePart.Head),
      [SlimePart.LeftArm]: this.calcComboPoints(SlimePart.LeftArm),
      [SlimePart.RightArm]: this.calcComboPoints(SlimePart.RightArm),
      [SlimePart.Face]: this.calcComboPoints(SlimePart.Face),
    };

    const stats = new FullStatGroup();
    stats.hp = calcAttr(
      'hp',
      SlimePart.Body,
      SlimePart.LeftArm,
      SlimePart.RightArm,
      SlimePart.Face
    );
    stats.atk = calcAttr(
      'atk',
      SlimePart.Body,
      SlimePart.LeftArm,
      SlimePart.RightArm,
      SlimePart.Face
    );
    stats.def = calcAttr(
      'def',
      SlimePart.Body,
      SlimePart.LeftArm,
      SlimePart.RightArm,
      SlimePart.Face
    );
    stats.crit = calcAttr('crit', SlimePart.Sub, SlimePart.Face);
    stats.critdmg = calcAttr('critdmg', SlimePart.Head, SlimePart.Face);
    stats.evade = calcAttr('evade', SlimePart.Sub, SlimePart.Face);
    stats.block = calcAttr('block', SlimePart.Head, SlimePart.Face);

    this._fullStatGroup = stats;

    return stats;
    function calcAttr(
      key: string,
      part1: SlimePart,
      part2: SlimePart,
      part3?: SlimePart,
      part4?: SlimePart
    ): Stat {
      // @ts-ignore
      const conv = config.statConversion[key];
      let points = 0;
      if (part1) points += calc(key, part1);
      if (part2) points += calc(key, part2);
      if (part3) points += calc(key, part3);
      if (part4) points += calc(key, part4);

      return { points, value: conv * points };

      function calc(key: string, part: SlimePart) {
        // @ts-ignore
        const partRarity = self[`${mapPartToString(part)}Details`].rarity - 1;
        // @ts-ignore
        const partAttrs = self[`${mapPartToString(part)}AttrsDetails`][key];
        return ((comboPoints[part] + config.attributePoints[partRarity]) * partAttrs) / 100;
      }
      function mapPartToString(part: SlimePart): string {
        switch (part) {
          case SlimePart.Body:
            return 'body';
          case SlimePart.Sub:
            return 'sub';
          case SlimePart.Head:
            return 'head';
          case SlimePart.LeftArm:
            return 'leftArm';
          case SlimePart.RightArm:
            return 'rightArm';
          case SlimePart.Face:
            return 'face';
        }
      }
    }
  }

  calcComboPoints(part: SlimePart): number {
    const counts = [0, 0, 0, 0, 0, 0, 0];
    counts[this.bodyDetails.class] += 1;
    counts[this.subDetails.class] += 1;
    counts[this.headDetails.class] += 1;
    counts[this.leftArmDetails.class] += 1;
    counts[this.rightArmDetails.class] += 1;
    counts[this.faceDetails.class] += 1;

    if (part === SlimePart.Body) return config.comboBonusPoints[counts[this.bodyDetails.class] - 1];
    else if (part === SlimePart.Sub)
      return config.comboBonusPoints[counts[this.subDetails.class] - 1];
    else if (part === SlimePart.Head)
      return config.comboBonusPoints[counts[this.headDetails.class] - 1];
    else if (part === SlimePart.LeftArm)
      return config.comboBonusPoints[counts[this.leftArmDetails.class] - 1];
    else if (part === SlimePart.RightArm)
      return config.comboBonusPoints[counts[this.rightArmDetails.class] - 1];
    else if (part === SlimePart.Face)
      return config.comboBonusPoints[counts[this.faceDetails.class] - 1];
    else return 0;
  }
}
