import React, { useMemo } from 'react';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IGeneMetadata, SkillType, SlimePart } from 'src/gql/types';
import { RarityLabel, GenePartLabel, GeneClassLabel } from 'src/components';
import { coreUtils } from '../../../common';
import { GenePartType } from '../../../globals/constants';
import InfoItem from './InfoItem';

interface GeneAboutsProps {
  metadata: IGeneMetadata;
}

const GeneAbouts = ({ metadata }: GeneAboutsProps) => {
  const { t } = useTranslation();

  const skillImageSrc = useMemo(() => {
    const extractedData = metadata.extractedData;
    if (extractedData && extractedData.class && extractedData.skill) {
      let isPassiveSkill;
      switch (extractedData.part) {
        case SlimePart.Head: {
          isPassiveSkill = extractedData.skill % 2 !== 0;
          break;
        }
        case SlimePart.Body:
        case SlimePart.Sub:
          isPassiveSkill = true;
          break;
        case SlimePart.RightArm:
        case SlimePart.LeftArm:
          isPassiveSkill = false;
          break;
        default:
          isPassiveSkill = false;
          break;
      }
      return coreUtils.getSkillImageUrl(
        isPassiveSkill ? SkillType.PASSIVE : SkillType.ACTIVE,
        extractedData.class,
        extractedData.skill
      );
    }
    return null;
  }, [metadata.extractedData]);

  const contentOfPartDetail = useMemo(() => {
    if (metadata.extractedData) {
      switch (metadata.extractedData.part) {
        case GenePartType.HEAD:
          return (
            <>
              <Text>{`Critical Dmg: ${metadata.extractedData.critdmg}%`}</Text>
              <Text>{`Block: ${metadata.extractedData.block}%`}</Text>
            </>
          );
        case GenePartType.SUB:
          return (
            <>
              <Text>{`Critical: ${metadata.extractedData.crit}%`}</Text>
              <Text>{`Evade: ${metadata.extractedData.evade}%`}</Text>
            </>
          );
        default:
          return (
            <>
              <Text>{`HP: ${metadata.extractedData.hp}%`}</Text>
              <Text>{`ATK: ${metadata.extractedData.atk}%`}</Text>
              <Text>{`DEF: ${metadata.extractedData.def}%`}</Text>
            </>
          );
      }
    }
    return null;
  }, [metadata.extractedData]);

  return (
    <Box>
      <VStack w="100%">
        <HStack alignItems={'start'} w="100%">
          <InfoItem title={t('Term:GeneClass')}>
            <GeneClassLabel geneClass={metadata.geneClass} />
          </InfoItem>
          <InfoItem title={t('Term:Part')}>
            <GenePartLabel genePart={metadata.part} />
          </InfoItem>
          <InfoItem title={t('Term:Rarity')}>
            <RarityLabel rarity={metadata.rarity} />
          </InfoItem>
          {metadata.extractedData && (
            <>
              <InfoItem title={t('Term:Skill')}>
                <Image src={skillImageSrc || ''} alt="skill" w="60px" />
              </InfoItem>
            </>
          )}
        </HStack>
        {metadata.extractedData && (
          <Box p={5} shadow="md" borderWidth="1px" rounded={5} width="100%">
            <HStack justify="space-around" w="100%">
              {contentOfPartDetail}
            </HStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default React.memo(GeneAbouts);
