import has from 'lodash/has';
import { useCallback, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { useOnConnectedWithMetaMask } from 'src/hooks';

interface IDoConnectToMetamask {
  onMetaMaskNotInstalled: () => void;
  onMetaMaskLocked: () => void;
  onMetaMaskHasNotAddedBSC: () => void;
}

const useDoConnectToMetaMask = ({
  onMetaMaskNotInstalled,
  onMetaMaskLocked,
  onMetaMaskHasNotAddedBSC,
}: IDoConnectToMetamask) => {
  const onConnectedWithMetaMask = useOnConnectedWithMetaMask();
  const [isRequestingConnectMetaMask, setIsRequestingConnectMetaMask] = useState<boolean>(false);
  const doConnectToMetaMask = useCallback(async () => {
    if (!MetamaskProvider.checkIsInstalled()) {
      onMetaMaskNotInstalled();
      return;
    }
    try {
      setIsRequestingConnectMetaMask(true);
      if (!MetamaskProvider.checkIsConnectedToBSCNetwork()) {
        await MetamaskProvider.requestSwitchToBSCNetwork();
      }
      await MetamaskProvider.requestConnect();
      const publicAddress = await MetamaskProvider.getAccountAddress();
      if (publicAddress) {
        onConnectedWithMetaMask(publicAddress);
      }
    } catch (err: any) {
      if (has(err, 'code')) {
        switch (err.code) {
          case 4001: {
            onMetaMaskLocked();
            break;
          }
          case 4902: {
            onMetaMaskHasNotAddedBSC();
            break;
          }
        }
      }
    }
    setIsRequestingConnectMetaMask(false);
  }, [onConnectedWithMetaMask, onMetaMaskLocked, onMetaMaskHasNotAddedBSC, onMetaMaskNotInstalled]);
  return [doConnectToMetaMask, isRequestingConnectMetaMask];
};

export default useDoConnectToMetaMask;
