import configureStore from './store';
import { AuthTypes } from './authentication';
import { WalletTypes } from './wallet';
import { MarketplaceTypes } from './marketplace';
import { AccountTypes } from './account';
import { InventoryTypes } from './inventory';
import { AppTypes } from './app';
import { DashboardTypes } from './dashboard';

export const { store, persistor } = configureStore();

export interface RootReducerState {
  auth: AuthTypes.AuthState;
  wallet: WalletTypes.WalletState;
  marketplace: MarketplaceTypes.MarketplaceState;
  account: AccountTypes.AccountState;
  inventory: InventoryTypes.InventoryState;
  app: AppTypes.AppState;
  dashboard: DashboardTypes.DashboardState;
}

export * from './authentication';
export * from './wallet';
export * from './marketplace';
export * from './account';
export * from './inventory';
export * from './app';
export * from './dashboard';
