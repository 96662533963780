import { useCallback, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { sleep } from 'src/common/utils';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';

const useRemoveNFTFromFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [isRemovingNFTFromFixedPriceSale, setIsRemovingNFTFromFixedPriceSale] = useState<
    boolean | undefined
  >();
  const onRemoveNFTFromFixedPriceSaleMarket = useCallback(
    (saleId: string, onCancelSuccess?: (txid: string) => void) => {
      setError(undefined);
      setIsRemovingNFTFromFixedPriceSale(true);
      MetamaskProvider.removeSaleFromFixedSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType),
        saleId
      )
        .then(async (receipt) => {
          await sleep(5000);
          if (onCancelSuccess) {
            onCancelSuccess(receipt.transactionHash);
          }
        }) // For delay confirmation
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsRemovingNFTFromFixedPriceSale(false);
        });
    },
    [nftType, setIsRemovingNFTFromFixedPriceSale]
  );

  return [isRemovingNFTFromFixedPriceSale, onRemoveNFTFromFixedPriceSaleMarket, error];
};

export default useRemoveNFTFromFixedPriceSaleMarket;
