import { useCallback, useEffect, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';

const useDoSwitchMetaMaskToBSCNetwork = () => {
  const [isConnectedToBSCNetwork, setIsConnectedToBSCNetwork] = useState(false);
  const [isRequestingSwitchMetaMaskToBSCNetwork, setIsRequestingSwitchMetaMaskToBSCNetwork] =
    useState<boolean>(false);

  const checkIsConnectedToBSCNetwork = useCallback(() => {
    if (MetamaskProvider.checkIsConnectedToBSCNetwork()) {
      setIsConnectedToBSCNetwork(true);
    } else {
      setIsConnectedToBSCNetwork(false);
    }
  }, []);

  useEffect(() => {
    if (MetamaskProvider.checkIsInstalled()) {
      checkIsConnectedToBSCNetwork();
      MetamaskProvider.addListenerNetworkChange(() => {
        checkIsConnectedToBSCNetwork();
      });
    }
  }, [checkIsConnectedToBSCNetwork]);

  const doSwitchMetaMaskToBSCNetwork = useCallback(async () => {
    try {
      setIsRequestingSwitchMetaMaskToBSCNetwork(true);
      if (!MetamaskProvider.checkIsConnectedToBSCNetwork()) {
        await MetamaskProvider.requestSwitchToBSCNetwork();
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsRequestingSwitchMetaMaskToBSCNetwork(false);
    }
  }, []);
  return [
    isConnectedToBSCNetwork,
    isRequestingSwitchMetaMaskToBSCNetwork,
    doSwitchMetaMaskToBSCNetwork,
  ];
};

export default useDoSwitchMetaMaskToBSCNetwork;
