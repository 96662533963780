import { Image, HStack, DeepPartial, Text } from '@chakra-ui/react';
import { IGeneMetadata, INFT, TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import { NFTType } from 'src/globals/constants';
import React from 'react';

interface Props {
  item: DeepPartial<INFT>;
}

const UserActivityRowItem = (props: Props) => {
  const { item } = props;

  const nftType = item.type as TNFTType;

  let imageUrl = null;
  if (item.tokenId) {
    imageUrl = coreUtils.getNftImage(
      nftType,
      item.tokenId,
      item.templateId || 0,
      !!(item?.metadata as IGeneMetadata)?.extractedData,
      (item?.metadata as IGeneMetadata)?.extractedData?.variant
    );
  }

  return (
    <HStack>
      {imageUrl ? (
        nftType === NFTType.LAND ? (
          <Image {...styles.landImage} src={imageUrl} />
        ) : (
          <Image {...styles.image} src={imageUrl} />
        )
      ) : (
        <div />
      )}
      <div>
        <Text>{(item?.metadata as any)?.name}</Text>
      </div>
    </HStack>
  );
};

const styles = {
  image: {
    height: '50px',
    objectFit: 'contain' as 'contain',
  },
  landImage: {
    height: '50px',
    objectFit: 'contain' as 'contain',
    borderRadius: '4px',
    border: '1px solid white',
  },
};

export default UserActivityRowItem;
