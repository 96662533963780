import { useCallback, useEffect, useState } from 'react';
import MetamaskProvider from 'src/metamask-provider';
import { TNFTType } from 'src/gql/types';
import { coreUtils } from 'src/common';
import { useIsMounted } from '../../../hooks';

const useApproveNFTToFixedPriceSaleMarket = (nftType: TNFTType) => {
  const [error, setError] = useState<string | undefined>();
  const [tokenId, setTokenId] = useState<number | undefined>();
  const [isGettingApprovedStatus, setIsGettingApprovedStatus] = useState<boolean | undefined>();
  const [isApprovingForSale, setIsApprovingForSale] = useState<boolean | undefined>();
  const [isApprovedForSale, setIsApprovedForSale] = useState<boolean | undefined>();
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted()) {
      setError(undefined);
      setIsGettingApprovedStatus(true);
      MetamaskProvider.checkApprovedAllNFTToFixedPriceSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType)
      )
        .then((isApproved) => {
          if (isMounted()) setIsApprovedForSale(isApproved);
        })
        .catch((error) => {
          if (isMounted()) setError(error.message);
          console.error(error);
        })
        .finally(() => {
          if (isMounted()) setIsGettingApprovedStatus(false);
        });
    }
  }, [isMounted, nftType, tokenId, setIsGettingApprovedStatus]);

  // Only call approve if the tokenId is set and the token is not approved
  const onApprovedNFTToFixedPriceSaleMarket = useCallback(() => {
    if (!isApprovedForSale) {
      setError(undefined);
      setIsApprovingForSale(true);
      MetamaskProvider.setApprovedNFTToFixedPriceSaleMarket(
        coreUtils.getNFTContractNameByNftType(nftType),
        true
      )
        .then(() => {
          setIsApprovedForSale(true);
        })
        .catch((error) => {
          setError(error.message);
          console.error(error);
        })
        .finally(() => {
          setIsApprovingForSale(false);
        });
    }
  }, [nftType, isApprovedForSale, setIsApprovingForSale, setIsApprovedForSale]);

  return [
    onApprovedNFTToFixedPriceSaleMarket,
    setTokenId,
    isGettingApprovedStatus,
    isApprovingForSale,
    isApprovedForSale,
    error,
  ];
};

export default useApproveNFTToFixedPriceSaleMarket;
